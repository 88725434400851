import React, {useState} from 'react';
import PropTypes from 'prop-types';
import DeleteStack from './delete-stack';
import StackEmbed from './stack-embed';
import StackBadge from './stack-badge';
import {
  EDIT_TOOLS,
  EDIT_STACK_INFO,
  CLAIM_STACK,
  JOIN_COMPANY,
  STACK_EMBED,
  STACK_BADGE,
  SETTINGS_LABELS,
  SETTINGS_ICONS,
  MANAGE_TEAM,
  DELETE_STACK
} from '../../../../../shared/constants/settings';
import MenuPopover from '../../../../../shared/library/popovers/menu';
import {withSendAnalyticsEvent} from '../../../../../shared/enhancers/analytics-enhancer';
import {SP_CLICKED_SETTINGS, SP_CLICKED_SETTINGS_ITEM} from '../../../constants';

const onStackEmbedDismiss = ({setStackEmbedModal}) => setStackEmbedModal(false);
const onStackBadgeDismiss = ({setStackBadgeModal}) => setStackBadgeModal(false);
const Menu = ({
  option,
  id,
  ownerUrl,
  name,
  slug,
  permissions,
  hasOwner,
  type,
  isUserImpersonated,
  teamMember,
  isOwner,
  services,
  embedUrl,
  isMobile,
  sendAnalyticsEvent,
  path,
  privateStack,
  companySlug
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showStackEmbedModal, setStackEmbedModal] = useState(false);
  const [showStackBadgeModal, setStackBadgeModal] = useState(false);
  const handleMenuClick = setShowDeleteModal => option => {
    sendAnalyticsEvent(SP_CLICKED_SETTINGS_ITEM, {item_name: option});
    switch (option) {
      case EDIT_TOOLS:
        window.location = `/stack-edit${path}/tool-selection`;
        break;
      case EDIT_STACK_INFO:
        window.location = `/stack-edit${path}/info`;
        break;
      case CLAIM_STACK:
        window.location = `/claim-stack${path}`;
        break;
      case JOIN_COMPANY:
        window.location = `/become-company-member?company=${name}`;
        break;
      case STACK_EMBED:
        setStackEmbedModal(!showStackEmbedModal);
        break;
      case STACK_BADGE:
        setStackBadgeModal(!showStackBadgeModal);
        break;
      case DELETE_STACK:
        setShowDeleteModal(!showDeleteModal);
        break;
      case MANAGE_TEAM:
        window.location = `/companies/${companySlug}/teams/`;
        break;
    }
  };
  const canDeletePermission = permissions && permissions.edit && permissions.delete;
  const canEditPermission = permissions && permissions.edit;
  const userCanDelete = setting =>
    !isUserImpersonated &&
    setting !== CLAIM_STACK &&
    setting !== JOIN_COMPANY &&
    setting !== STACK_EMBED &&
    setting !== STACK_BADGE &&
    setting !== MANAGE_TEAM &&
    canDeletePermission;
  const userCanEdit = setting =>
    (setting === EDIT_TOOLS || setting === EDIT_STACK_INFO) &&
    canEditPermission &&
    !isUserImpersonated;
  const userCanClaim = setting =>
    setting === CLAIM_STACK && type === 'Company' && !hasOwner && !isUserImpersonated;
  const userCanJoin = setting =>
    setting === JOIN_COMPANY &&
    type === 'Company' &&
    hasOwner &&
    !teamMember &&
    !isUserImpersonated;
  const userCanEmbedStack = setting =>
    setting === STACK_EMBED && isOwner && !isMobile && !privateStack && !isUserImpersonated;
  const userCanShareBadge = setting =>
    setting === STACK_BADGE && isOwner && !privateStack && !isUserImpersonated;
  const userCanManageTeam = setting =>
    setting === MANAGE_TEAM &&
    privateStack &&
    type === 'Company' &&
    canDeletePermission &&
    !isUserImpersonated;

  const allMenuOptions = [
    EDIT_TOOLS,
    EDIT_STACK_INFO,
    STACK_BADGE,
    STACK_EMBED,
    CLAIM_STACK,
    JOIN_COMPANY,
    DELETE_STACK,
    MANAGE_TEAM
  ].map(setting => ({
    id: setting,
    label: SETTINGS_LABELS[setting],
    icon: SETTINGS_ICONS[setting],
    visible:
      userCanEdit(setting) ||
      userCanDelete(setting) ||
      userCanClaim(setting) ||
      userCanJoin(setting) ||
      userCanEmbedStack(setting) ||
      userCanShareBadge(setting) ||
      userCanManageTeam(setting)
  }));
  const visibleMenuItems = allMenuOptions
    .filter(item => {
      return item.visible === true;
    })
    .map(item => {
      return item;
    });

  return (
    <React.Fragment>
      {visibleMenuItems.length !== 0 && (
        <MenuPopover
          options={visibleMenuItems}
          onShowMenuEvent={SP_CLICKED_SETTINGS}
          onClick={handleMenuClick(setShowDeleteModal)}
          style={{
            marginLeft: 'auto'
          }}
        />
      )}
      {showDeleteModal && (
        <DeleteStack
          name={name}
          ownerUrl={ownerUrl}
          id={id}
          option={option}
          setShowDeleteModal={setShowDeleteModal}
        />
      )}
      {showStackEmbedModal && (
        <StackEmbed
          ownerUrl={ownerUrl}
          name={name}
          slug={slug}
          onDismiss={() => onStackEmbedDismiss({setStackEmbedModal})}
          services={services}
          embedUrl={embedUrl}
        />
      )}
      {showStackBadgeModal && (
        <StackBadge
          name={name}
          path={path}
          onDismiss={() => onStackBadgeDismiss({setStackBadgeModal})}
        />
      )}
    </React.Fragment>
  );
};

Menu.propTypes = {
  name: PropTypes.string,
  ownerUrl: PropTypes.string,
  id: PropTypes.string,
  option: PropTypes.string,
  slug: PropTypes.string,
  permissions: PropTypes.object,
  hasOwner: PropTypes.bool,
  privateStack: PropTypes.bool,
  type: PropTypes.string,
  isUserImpersonated: PropTypes.bool,
  teamMember: PropTypes.bool,
  isOwner: PropTypes.bool,
  services: PropTypes.array,
  embedUrl: PropTypes.string,
  isMobile: PropTypes.bool,
  sendAnalyticsEvent: PropTypes.func,
  path: PropTypes.string,
  companySlug: PropTypes.string
};

export default withSendAnalyticsEvent(Menu);
