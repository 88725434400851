/* eslint react/prop-types: 0 */
import React, {useContext, useState} from 'react';
import glamorous from 'glamorous';
import {RouteContext} from '../../../shared/enhancers/router-enhancer';
import {stackProfile, stackTools} from '../../../data/stack-profile/queries';
import {Query} from 'react-apollo';
import StackProfileAnalyticsProvider from '../components/shared/analytics-provider';
import Circular, {LARGE} from '../../../shared/library/indicators/indeterminate/circular';
import {flattenEdges} from '../../../shared/utils/graphql';

const Loader = glamorous.div({
  display: 'flex',
  justifyContent: 'center',
  margin: '100px 0'
});

const withStackProfile = Component => props => {
  const {ownerSlug, stackSlug, signin} = useContext(RouteContext);
  const [activeServiceId, setActiveService] = useState(null);
  const [limitDecisions, setLimitDecisions] = useState(true);
  let toolItems = {};

  if (ownerSlug) {
    const id = `${ownerSlug}/${stackSlug}`;

    return (
      <Query query={stackProfile} variables={{id: id}}>
        {({data, loading: loadingMain}) => {
          const stackProfileData = data?.stackProfile;
          if (loadingMain && !stackProfileData)
            return (
              <Loader>
                <Circular size={LARGE} />
              </Loader>
            );
          return (
            <Query
              query={stackTools}
              variables={{id: id, companySlug: ownerSlug}}
              errorPolicy="ignore"
            >
              {({data: {stackProfile: stackToolsData}, loading: loadingStackTools}) => {
                const hasData = stackToolsData && stackToolsData.stackItems;
                if (loadingStackTools && !stackToolsData)
                  return (
                    <Loader>
                      <Circular size={LARGE} />
                    </Loader>
                  );
                if (hasData) {
                  toolItems = flattenEdges(hasData).map(item => {
                    return {
                      ...item.tool,
                      stage: item.versionStage
                        ? {
                            ...item.versionStage,
                            context: item.versionStageContext
                          }
                        : {
                            ...item.tool.adoptionStage,
                            context: item.tool.adoptionStageContext
                          },
                      versionStage: item.versionStage ? item.versionStage.name : null,
                      versionNumber: item.versionNumber,
                      sourceFilename: item.sourceFilename,
                      displayCheckRunUrl: item.displayCheckRunUrl
                    };
                  });
                }
                return (
                  <StackProfileAnalyticsProvider
                    stack={stackProfileData}
                    stackOwnerType={props.stackOwnerType}
                    activeServiceId={activeServiceId}
                    loading={loadingMain}
                    toolItems={toolItems}
                  >
                    <Component
                      {...props}
                      stack={stackProfileData}
                      loading={loadingMain}
                      signin={signin}
                      limitDecisions={limitDecisions}
                      setLimitDecisions={setLimitDecisions}
                      toolItems={toolItems}
                      activeServiceId={activeServiceId}
                      setActiveService={setActiveService}
                      id={id}
                    />
                  </StackProfileAnalyticsProvider>
                );
              }}
            </Query>
          );
        }}
      </Query>
    );
  }
  return <h1>Not Found</h1>;
};

export default withStackProfile;
