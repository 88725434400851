import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import {CHARCOAL} from '../../../../shared/style/colors';
import {watchStack} from '../../../../data/stack-notification/mutations';
import {BASE_TEXT} from '../../../../shared/style/typography';
import {ApolloContext} from '../../../enhancers/graphql-enhancer';
import CheckIcon from '../../../library/icons/check.svg';
import {CurrentUserContext} from '../../../enhancers/current-user-enhancer';

const Shape = glamorous.div({
  minWidth: 15,
  minHeight: 11,
  marginTop: 2
});

const Name = glamorous.div({
  ...BASE_TEXT,
  textShadow: '0 0 4px rgba(0, 0, 0, 0)',
  fontSize: 12,
  fontWeight: 600,
  color: CHARCOAL,
  textAlign: 'left'
});

const Description = glamorous.div({
  ...BASE_TEXT,
  marginTop: 2,
  marginBottom: 6,
  textShadow: '0 0 4px rgba(0, 0, 0, 0)',
  fontSize: 12,
  fontWeight: 'normal',
  color: '#b4b4b4',
  textAlign: 'left'
});

const Header = glamorous.div({
  ...BASE_TEXT,
  marginLeft: 14,
  fontSize: 11,
  fontWeight: 600,
  letterSpacing: -0.02,
  color: '#3a3a3a',
  width: '100%',
  paddingBottom: 8,
  textAlign: 'start'
});

const Seperator = glamorous.div({
  height: 1,
  margin: '2px 0 1px',
  borderBottom: 'solid 1px #e1e1e1',
  width: '100%'
});

const Content = glamorous.div({
  display: 'flex'
});

const ListContainer = glamorous.div();

const ListItem = glamorous.div({
  padding: '10px 13px 0 13px',
  cursor: 'pointer',
  ':hover': {
    backgroundColor: '#f2f2f2'
  }
});

const TextContainer = glamorous.div({
  marginLeft: 13
});

const LevelContainer = ({activeStackLevel, stackId, setNotifyMenu, levels, setLevel}) => {
  const client = useContext(ApolloContext);
  const currentUser = useContext(CurrentUserContext);
  const isUserImpersonated = currentUser && currentUser.impersonated;

  const handleMenuClick = level => {
    if (level && !isUserImpersonated) {
      client
        .mutate({
          mutation: watchStack,
          variables: {
            level: level,
            stackId: stackId
          }
        })
        .then(result => {
          if (result && result.data) setLevel(result.data.watchStack.level);
          setNotifyMenu(false);
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          /* eslint-disable no-console */
          console.log('Something went wrong!', error);
        });
    } else {
      setNotifyMenu(false);
    }
  };

  return (
    <>
      <Header>Notifications</Header>
      <Seperator />
      <ListContainer>
        {levels.map((item, index) => {
          const icon = item.slug === activeStackLevel ? <CheckIcon /> : '';
          return (
            <ListItem key={`item-${index}`}>
              <Content
                onClick={() => {
                  handleMenuClick(item.slug);
                }}
              >
                <Shape>{icon}</Shape>
                <TextContainer>
                  <Name>{item.name}</Name>
                  <Description>{item.description}</Description>
                </TextContainer>
              </Content>
            </ListItem>
          );
        })}
      </ListContainer>
    </>
  );
};

LevelContainer.propTypes = {
  activeStackLevel: PropTypes.string,
  stackId: PropTypes.string,
  setNotifyMenu: PropTypes.func,
  setLevel: PropTypes.func,
  levels: PropTypes.array
};

export default LevelContainer;
