import React from 'react';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';
import {FOCUS_BLUE, ASH} from '../../../style/colors';

const Circle = glamorous.circle(
  {
    position: 'absolute',
    transform: 'rotate(-90deg)',
    transformOrigin: '50% 50%'
  },
  ({animate, animationDuration}) => ({
    transition: animate
      ? `${animationDuration}s stroke-dashoffset, ${animationDuration}s stroke`
      : 'none'
  })
);

const ProgressRing = ({
  radius,
  strokeWidth,
  strokeColor,
  fill,
  progress,
  background,
  backgroundColor,
  backgroundOpacity,
  animate,
  animationDuration
}) => {
  const normalizedRadius = radius - strokeWidth * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (progress / 100) * circumference;
  return (
    <svg height={radius * 2} width={radius * 2}>
      {background && (
        <Circle
          stroke={backgroundColor}
          fill={fill}
          opacity={backgroundOpacity}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference + ' ' + circumference}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
          animate={animate}
          animationDuration={animationDuration}
        />
      )}
      <Circle
        stroke={strokeColor}
        fill={fill}
        strokeWidth={strokeWidth}
        strokeDasharray={circumference + ' ' + circumference}
        style={{strokeDashoffset}}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
        animate={animate}
        animationDuration={animationDuration}
      />
    </svg>
  );
};

ProgressRing.propTypes = {
  progress: PropTypes.number,
  radius: PropTypes.number,
  strokeWidth: PropTypes.number,
  strokeColor: PropTypes.string,
  fill: PropTypes.string,
  background: PropTypes.bool,
  backgroundColor: PropTypes.string,
  animate: PropTypes.bool,
  animationDuration: PropTypes.number,
  backgroundOpacity: PropTypes.number
};

ProgressRing.defaultProps = {
  progress: 0,
  radius: 60,
  strokeWidth: 4,
  strokeColor: FOCUS_BLUE,
  fill: 'transparent',
  background: true,
  backgroundColor: ASH,
  animate: true,
  animationDuration: 0.35,
  backgroundOpacity: 0.2
};

export default ProgressRing;
