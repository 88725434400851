import React, {Fragment, useEffect} from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import {withMutation} from '../../../../shared/enhancers/graphql-enhancer.js';
import {trackViews} from '../../../../data/shared/mutations.js';
import PostCard from '../../../../shared/library/cards/post';
import {callTrackViews} from '../../../../../app/shared/utils/trackViews.js';

const List = glamorous.div({
  '>*': {
    marginBottom: 20
  }
});

const StackProfileDecisions = ({decisions, children, trackViews}) => {
  useEffect(() => {
    const decisionIds = decisions.map(d => d.id);

    callTrackViews({
      trackViews,
      decisionIds,
      clientContext: `StackProfileDecisions-${window.location.pathname}`
    });
  }, [decisions]);

  return (
    <Fragment>
      {children}
      <List>
        {decisions.map((item, index) => (
          <PostCard
            key={item.id}
            post={item}
            sharedProps={{}}
            disableFirstRun={true}
            isFirstNonUpvoted={false}
            analyticsPayload={{cardPosition: index + 1}}
            isPrivate={true}
            onStackProfile={true}
            lazyLoadImages={false}
          />
        ))}
      </List>
    </Fragment>
  );
};

StackProfileDecisions.propTypes = {
  decisions: PropTypes.array,
  children: PropTypes.element,
  trackViews: PropTypes.func
};

export default withMutation(trackViews, mutate => ({
  trackViews: (decisionIds, clientContext) => mutate({variables: {decisionIds, clientContext}})
}))(StackProfileDecisions);
