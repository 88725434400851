import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import {FOCUS_BLUE, WHITE} from '../../style/colors';
const Message = glamorous.div({
  height: 45,
  color: WHITE,
  background: FOCUS_BLUE,
  lineHeight: '45px',
  paddingLeft: 20
});
const SucessMessage = ({message}) => {
  return <Fragment>{message && <Message>{message}</Message>}</Fragment>;
};

SucessMessage.propTypes = {
  message: PropTypes.string
};

export {SucessMessage as default};
