import {STACK_TYPE_COMPANY, SP_DECISION_SOURCE} from './constants';
import {stackDecisions, strengthMeter} from '../../data/stack-profile/queries';

export const defaultAnalyticsPayload = (stack, stackOwnerType, activeService) => {
  const isCompanyStack = stackOwnerType === STACK_TYPE_COMPANY;
  return {
    'page.name': 'Stack',
    selectedTool: activeService ? activeService.slug : null,
    stackType: stackOwnerType,
    stackId: stack.id,
    stackName: stack.name,
    decisionSource: SP_DECISION_SOURCE,
    [isCompanyStack ? 'companyName' : 'username']: isCompanyStack
      ? stack.owner.name
      : stack.owner.username,
    [isCompanyStack ? 'companyId' : 'userId']: stack.owner.id
  };
};

export const getActiveService = (activeServiceId, toolItems) =>
  activeServiceId ? toolItems.find(s => s.id === activeServiceId) : null;

export const getDecisionsForService = (decisions, serviceId) => {
  if (serviceId === null) {
    return [decisions, []];
  }
  let withService = [];
  let rest = [];
  decisions.forEach(d => {
    if (d.services.find(s => s.id === serviceId)) {
      withService.push(d);
    } else {
      rest.push(d);
    }
  });

  return [withService, rest];
};

export const normaliseOwnerMeta = (stack, type) => ({
  ownerName: type === STACK_TYPE_COMPANY ? stack.owner.name : stack.owner.displayName,
  ownerUrl: type === STACK_TYPE_COMPANY ? stack.owner.path : `/${stack.owner.username}`
});

export const normaliseTeamMember = (subject, userid, type) => {
  let teamMemberUserIds = [];
  if (type === STACK_TYPE_COMPANY && userid) {
    Object.keys(subject.team.edges).map(item =>
      teamMemberUserIds.push(subject.team.edges[item].node.id)
    );
    return teamMemberUserIds.includes(userid.id);
  }
};

export const normaliseDecisions = stackProfile =>
  stackProfile && stackProfile.stackDecisions
    ? stackProfile.stackDecisions.edges.map(e => e.node)
    : [];

export const normaliseDecisionsCount = stackProfile =>
  stackProfile && stackProfile.stackDecisions ? stackProfile.stackDecisions.count : 0;

export const promptForService = activeService => {
  return activeService
    ? {
        title: '',
        message: `Share a stack decision on why you chose ${
          activeService.name
        } and how you use it in your stack!`
      }
    : null;
};

export const decisionForService = (activeService, stackOwner, stackOwnerType) => {
  const company =
    stackOwnerType === STACK_TYPE_COMPANY
      ? {
          id: stackOwner.id,
          imageUrl: stackOwner.imageUrl,
          name: stackOwner.name
        }
      : null;
  return activeService
    ? {
        rawContent: `${stackOwnerType === STACK_TYPE_COMPANY ? 'We' : 'I'} use @{${
          activeService.name
        }}|tool:${activeService.id}| because `,
        id: null,
        company
      }
    : {rawContent: '', id: null, company};
};

export const refetchQueriesForOwnerType = (ownerSlug, stackSlug) => {
  const id = `${ownerSlug}/${stackSlug}`;
  return [{query: stackDecisions, variables: {id}}, {query: strengthMeter, variables: {id}}];
};
