import React from 'react';
import PropTypes from 'prop-types';
import DeleteModal from '../../../../../shared/library/modals/delete/delete-modal';
import {compose} from 'react-apollo';
import {destroyStack} from '../../../../../data/stack-profile/mutations';
import {withMutation} from '../../../../../shared/enhancers/graphql-enhancer';

const onDismiss = ({setShowDeleteModal}) => setShowDeleteModal(false);
const onConfirmationDismiss = ({setShowDeleteModal, ownerUrl}) => {
  setShowDeleteModal(false);
  window.location = `${ownerUrl}`;
};

const DeleteStack = ({name, ownerUrl, id, onDeleteStack, setShowDeleteModal}) => {
  return (
    <React.Fragment>
      <DeleteModal
        objectType={`${name} Stack`}
        objectId={id}
        onDismiss={() => onDismiss({setShowDeleteModal})}
        onSubmit={onDeleteStack}
        onConfirmationDismiss={() => onConfirmationDismiss({setShowDeleteModal, ownerUrl})}
      />
    </React.Fragment>
  );
};

DeleteStack.propTypes = {
  name: PropTypes.string,
  ownerUrl: PropTypes.string,
  id: PropTypes.string,
  onDeleteStack: PropTypes.func,
  setShowDeleteModal: PropTypes.func
};

export default compose(
  withMutation(destroyStack, mutate => ({
    onDeleteStack: id =>
      mutate({
        variables: {id}
      })
  }))
)(DeleteStack);
