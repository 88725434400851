import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import ProgressRing from '../../indicators/progress-ring';
import SimpleButton from '../../buttons/base/simple.jsx';
import useVisibilityTracking from '../../../utils/hooks/visibility';
import {BASE_TEXT, WEIGHT} from '../../../style/typography';
import {ASH, CHARCOAL, GUNSMOKE, FOCUS_BLUE, PUMPKIN, GOSSAMER} from '../../../style/colors';

export const ROW = 'row';
export const COLUMN = 'column';

const Container = glamorous.div(
  {
    border: `1px solid ${ASH}`,
    padding: 25,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  ({theme}) => ({
    flexDirection: theme
  })
);

const ProgressRingContainer = glamorous.div({
  position: 'relative'
});

const Content = glamorous.div(
  {
    display: 'flex',
    alignItems: 'center'
  },
  ({theme}) => ({
    flexDirection: theme
  })
);

const Copy = glamorous.div(({theme}) => ({
  marginLeft: theme === ROW ? 10 : 0
}));

const Label = glamorous.div(
  {
    ...BASE_TEXT,
    fontSize: 15,
    fontWeight: WEIGHT.BOLD,
    color: CHARCOAL
  },
  ({theme}) => ({
    textAlign: theme === COLUMN ? 'center' : 'left'
  })
);

const Description = glamorous.div(
  {
    ...BASE_TEXT,
    color: GUNSMOKE
  },
  ({theme}) => ({
    textAlign: theme === COLUMN ? 'center' : 'left'
  })
);

const Action = glamorous.div(({theme}) => ({
  marginTop: theme === COLUMN ? 15 : 0
}));

const Match = glamorous.div(
  {
    ...BASE_TEXT,
    position: 'absolute',
    top: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: GOSSAMER
  },
  ({circumfrence, color}) => ({
    height: circumfrence,
    width: circumfrence,
    color
  })
);

const MatchPercentage = glamorous.div({
  fontWeight: WEIGHT.BOLD
});

const MatchLabel = glamorous.div({
  fontSize: 10
});

const Link = glamorous.a({
  textDecoration: 'none'
});

const RADIUS = 35;
const CIRCUMFRENCE = RADIUS * 2;

const matchColor = percent => {
  if (percent === 0) {
    return ASH;
  } else if (0 < percent && percent < 60) {
    return PUMPKIN;
  } else if (60 <= percent && percent < 80) {
    return FOCUS_BLUE;
  } else {
    return GOSSAMER;
  }
};

const StackMatchCard = ({matchPercentage, theme, subject, animationDuration}) => {
  const description = `See if you're a match for ${subject}'s Jobs`;
  const [progress, setProgress] = useState(0);
  const [percentageLabel, setPercentageLabel] = useState(0);
  const color = matchColor(progress);
  const el = useRef(null);

  const isVisible = useVisibilityTracking(el);

  useEffect(() => {
    let animationTimer;
    if (isVisible) {
      const modifier = matchPercentage > progress ? 1 : -1;
      const animationRange = Math.abs(matchPercentage - progress);
      const interval = animationDuration / animationRange;
      let count = 0;
      if (animationRange > 0) {
        animationTimer = setInterval(() => {
          count += modifier;
          const label = progress + count;
          setPercentageLabel(label);
          if (Math.abs(count) >= animationRange) {
            clearInterval(animationTimer);
          }
        }, interval);
      } else {
        setPercentageLabel(0);
      }
      setProgress(matchPercentage);
    } else {
      setProgress(0);
      setPercentageLabel(0);
    }
    return () => clearInterval(animationTimer);
  }, [matchPercentage, isVisible]);

  return (
    <Container theme={theme} innerRef={el}>
      <Content theme={theme}>
        <ProgressRingContainer>
          <ProgressRing
            progress={progress}
            radius={RADIUS}
            strokeWidth={2}
            strokeColor={color}
            backgroundColor={color}
            animationDuration={animationDuration / 1000}
          />
          <Match circumfrence={CIRCUMFRENCE} color={color}>
            <MatchPercentage>{`${percentageLabel}%`}</MatchPercentage>
            <MatchLabel>MATCH</MatchLabel>
          </Match>
        </ProgressRingContainer>
        <Copy theme={theme}>
          <Label theme={theme}>Stack Match</Label>
          <Description theme={theme}>{description}</Description>
        </Copy>
      </Content>
      <Action theme={theme}>
        <Link href="/match">
          <SimpleButton active={true}>Add your stack</SimpleButton>
        </Link>
      </Action>
    </Container>
  );
};

StackMatchCard.propTypes = {
  matchPercentage: PropTypes.number,
  theme: PropTypes.oneOf([ROW, COLUMN]),
  subject: PropTypes.string,
  animationDuration: PropTypes.number
};

StackMatchCard.defaultProps = {
  matchPercentage: 0,
  theme: COLUMN,
  animationDuration: 1000
};

export default StackMatchCard;
