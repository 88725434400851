import React from 'react';
import PropTypes from 'prop-types';
import BaseModal, {ButtonPanel} from '../../../../shared/library/modals/base/modal';
import {withPortal} from '../../../../shared/library/modals/base/portal';
import SimpleButton from '../../../../shared/library/buttons/base/simple.jsx';

const ConfirmationModal = ({onDismiss}) => {
  return (
    <BaseModal title="Your question has been submitted!" width="500" onDismiss={onDismiss}>
      {`We'll let you know when the the stack owners respond, thank you!`}
      <ButtonPanel>
        <SimpleButton onClick={onDismiss}>Ok, got it</SimpleButton>
      </ButtonPanel>
    </BaseModal>
  );
};

ConfirmationModal.propTypes = {
  onDismiss: PropTypes.func
};

export default withPortal(ConfirmationModal);
