import React, {Component} from 'react';
import {compose} from 'react-apollo';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';
import SimpleButton from '../../../../shared/library/buttons/base/simple';
import {askQuestion} from '../../../../data/stackups/mutations';
import {withMutation} from '../../../../shared/enhancers/graphql-enhancer';
import {ASH} from '../../../../shared/style/colors';
import Indicator, {BUTTON} from '../../../../shared/library/indicators/indeterminate/circular';
import ErrorModal from './error-modal';
import {withSendAnalyticsEvent} from '../../../../shared/enhancers/analytics-enhancer';
import {
  STACKUPS_QUESTION_CLICK_SUBMIT_QUESTION,
  STACKUPS_QUESTION_FOCUS_COMPOSER,
  STACKUPS_QUESTION_FIRST_CHANGE_COMPOSER
} from '../../constants/analytics';
import {ID} from '../../../../shared/utils/graphql';

const QuestionComposerInner = glamorous.div({});

const ButtonPanel = glamorous.div({
  display: 'flex',
  justifyContent: 'flex-end'
});

const TextArea = glamorous.textarea({
  border: `1px solid ${ASH}`,
  margin: '10px 0',
  padding: 10,
  width: '100%',
  height: 180,
  resize: 'none',
  outline: 'none'
});

export class QuestionBody extends Component {
  static propTypes = {
    askQuestion: PropTypes.func,
    onDismiss: PropTypes.func,
    sendAnalyticsEvent: PropTypes.func,
    userId: ID,
    stackupName: PropTypes.string,
    stackupSlug: PropTypes.string,
    panelPosition: PropTypes.string,
    onSubmit: PropTypes.func
  };

  state = {
    content: '',
    error: '',
    saving: false,
    success: false,
    onFirstChange: false
  };

  handleDismissError = () => this.setState({error: false});

  handleChange = event => {
    const {sendAnalyticsEvent, userId, stackupName, stackupSlug, panelPosition} = this.props;
    const content = event.target.value;
    this.setState({content});
    if (!this.state.onFirstChange) {
      sendAnalyticsEvent(STACKUPS_QUESTION_FIRST_CHANGE_COMPOSER, {
        userId,
        stackupName,
        stackupSlug,
        panelPosition
      });
      this.setState({onFirstChange: true});
    }
  };

  handleOnFocus = () => {
    const {sendAnalyticsEvent, userId, stackupName, stackupSlug, panelPosition} = this.props;
    sendAnalyticsEvent(STACKUPS_QUESTION_FOCUS_COMPOSER, {
      userId,
      stackupName,
      stackupSlug,
      panelPosition
    });
  };

  handleAskQuestion = () => {
    const {
      askQuestion,
      onDismiss,
      sendAnalyticsEvent,
      userId,
      stackupName,
      stackupSlug,
      panelPosition,
      onSubmit
    } = this.props;
    const {content} = this.state;
    sendAnalyticsEvent(STACKUPS_QUESTION_CLICK_SUBMIT_QUESTION, {
      userId,
      stackupName,
      stackupSlug,
      content,
      panelPosition
    });
    this.setState({saving: true});
    askQuestion(content, stackupSlug)
      .then(() => {
        setTimeout(onDismiss, 1000);
        this.setState(
          {
            content: '',
            saving: false,
            success: true
          },
          () => {
            onSubmit && onSubmit();
          }
        );
      })
      .catch(err => {
        this.setState({
          saving: false,
          error: err.message
        });
      });
  };
  render() {
    const {content, saving, success, error} = this.state;
    const buttonText = success && !saving ? 'Success' : 'Submit';
    return (
      <QuestionComposerInner>
        <TextArea
          onChange={this.handleChange}
          value={content}
          placeholder="Enter your question here"
          name="content"
          onFocus={this.handleOnFocus}
        />
        <ButtonPanel>
          <SimpleButton
            style={{marginLeft: 10}}
            width={'11em'}
            disabled={content.length === 0 || saving}
            onClick={this.handleAskQuestion}
          >
            {saving ? <Indicator size={BUTTON} /> : buttonText}
          </SimpleButton>
        </ButtonPanel>
        {error && <ErrorModal detail={error} onDismiss={this.handleDismissError} />}
      </QuestionComposerInner>
    );
  }
}

export default compose(
  withMutation(askQuestion, mutate => ({
    askQuestion: (content, stackupSlug) => mutate({variables: {content, stackupSlug}})
  })),
  withSendAnalyticsEvent
)(QuestionBody);
