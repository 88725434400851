import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import {PAGE_WIDTH} from '../../../../shared/style/dimensions';
import Menu from './menu/index';
import {MobileContext} from '../../../../shared/enhancers/mobile-enhancer';
import Header from '../../../../shared/library/header';
import StackNotification from '../../../../shared/library/cards/stack-notification';
import {PrivateModeContext} from '../../../../shared/enhancers/private-mode-enchancer';
import {CurrentUserContext} from '../../../../shared/enhancers/current-user-enhancer';
import Timestamp from '../../../../shared/library/timestamp';
import Views from './../../../../shared/library/views';

const Page = glamorous.div({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column'
});

const TopContent = glamorous.div({
  display: 'flex',
  flexDirection: 'column',
  width: PAGE_WIDTH,
  height: '100%'
});

const ExtraContent = glamorous.div({
  display: 'flex',
  flexDirection: 'column'
});

const ChildContent = glamorous.div({
  display: 'flex',
  justifyContent: 'space-between'
});

const FixedNav = ({
  type,
  logoUrl,
  name,
  ownerUrl,
  description,
  viewCount,
  id,
  permissions,
  privateStack,
  slug,
  hasOwner,
  teamMember,
  services,
  isOwner,
  embedUrl,
  isVerifiedStack,
  owner,
  isCompany,
  isPrivate,
  path,
  websiteUrl,
  showTags,
  notificationLevel,
  stackNotificationPermission,
  org,
  updatedAt,
  repoStack
}) => {
  const isMobile = useContext(MobileContext);
  const privateMode = useContext(PrivateModeContext);
  const currentUser = useContext(CurrentUserContext);
  const isLinkClickable = privateMode && privateMode.myRole === 'admin' && owner && owner.amIOwner;
  const isUserImpersonated = currentUser && currentUser.impersonated;
  const privateCompany =
    currentUser &&
    currentUser.companies &&
    currentUser.companies.filter(company => company.privateMode)[0];
  let stackGithubUrl = '';
  let stackWebsiteUrl = '';
  if (websiteUrl && websiteUrl.includes('github')) stackGithubUrl = websiteUrl;
  else {
    stackWebsiteUrl = websiteUrl;
  }

  return (
    <React.Fragment>
      <Page>
        <TopContent>
          <Header
            logo={logoUrl}
            title={name}
            githubUrl={stackGithubUrl}
            website={stackWebsiteUrl}
            customStyle={{margin: '30px 0px'}}
            verified={isVerifiedStack}
            description={description}
            isCompany={isCompany}
            isMobile={isMobile}
            company={owner}
            metaDataStyle={{maxWidth: 550, paddingBottom: 0}}
            user={type === 'User' && owner}
            isPrivate={isPrivate}
            showTags={showTags}
            org={org}
            path={path}
            showIcon={true}
            showPublicIcon={true}
            isLinkClickable={isLinkClickable}
            slug={slug}
            privateCompany={privateCompany}
            component={
              <ExtraContent>
                {updatedAt && repoStack && (
                  <Timestamp
                    text="UPDATED"
                    date={updatedAt}
                    isDate={false}
                    customStyle={{marginBottom: 16, fontSize: 12, marginTop: 5}}
                  />
                )}
                <ChildContent>
                  {viewCount !== 0 && <Views viewCount={viewCount} />}
                  {stackNotificationPermission && privateStack && (
                    <StackNotification notificationLevel={notificationLevel} stackId={id} />
                  )}

                  <Menu
                    name={name}
                    ownerUrl={ownerUrl}
                    id={id}
                    slug={slug}
                    hasOwner={hasOwner}
                    permissions={permissions}
                    type={type}
                    isUserImpersonated={isUserImpersonated}
                    teamMember={teamMember}
                    services={services}
                    isOwner={isOwner}
                    embedUrl={embedUrl}
                    isMobile={isMobile}
                    path={path}
                    companySlug={owner.slug}
                    privateStack={privateStack}
                  />
                </ChildContent>
              </ExtraContent>
            }
          />
        </TopContent>
      </Page>
    </React.Fragment>
  );
};

FixedNav.propTypes = {
  type: PropTypes.string,
  logoUrl: PropTypes.string,
  name: PropTypes.string,
  ownerUrl: PropTypes.string,
  viewCount: PropTypes.number,
  id: PropTypes.string,
  permissions: PropTypes.object,
  slug: PropTypes.string,
  description: PropTypes.string,
  hasOwner: PropTypes.bool,
  teamMember: PropTypes.bool,
  isOwner: PropTypes.bool,
  privateStack: PropTypes.bool,
  services: PropTypes.array,
  embedUrl: PropTypes.string,
  isVerifiedStack: PropTypes.bool,
  owner: PropTypes.object,
  isCompany: PropTypes.bool,
  isPrivate: PropTypes.bool,
  path: PropTypes.string,
  websiteUrl: PropTypes.string,
  showTags: PropTypes.bool,
  notificationLevel: PropTypes.any,
  stackNotificationPermission: PropTypes.bool,
  org: PropTypes.any,
  updatedAt: PropTypes.string,
  repoStack: PropTypes.bool
};

export default FixedNav;
