import {CATCH_ALL_ROUTE, SIGN_IN_ROUTE, SLUG_CAPTURE} from '../../../shared/constants/routes';

export const routes = {
  [SIGN_IN_ROUTE]: (match, props, prevRouteContext) => ({
    ...prevRouteContext,
    signin: true
  }),
  [`^/${SLUG_CAPTURE}/${SLUG_CAPTURE}$`]: match => ({
    ownerSlug: match[1],
    stackSlug: match[2],
    hideDecisions: false,
    showStages: false
  }),
  [`^/${SLUG_CAPTURE}/${SLUG_CAPTURE}/main$`]: match => ({
    ownerSlug: match[1],
    stackSlug: match[2],
    hideDecisions: true,
    showStages: false
  }),
  [`^/${SLUG_CAPTURE}/${SLUG_CAPTURE}/stages$`]: match => ({
    ownerSlug: match[1],
    stackSlug: match[2],
    hideDecisions: false,
    showStages: true
  }),
  [`^/${SLUG_CAPTURE}/${SLUG_CAPTURE}/stages/main$`]: match => ({
    ownerSlug: match[1],
    stackSlug: match[2],
    hideDecisions: true,
    showStages: true
  }),
  [CATCH_ALL_ROUTE]: () => ({ownerSlug: null, stackSlug: null, hideDecisions: false})
};
