import React from 'react';
import PropTypes from 'prop-types';
import LoadMoreButton from '../../../../shared/library/buttons/load-more';

const SeeMore = ({onClick, text = 'See more', loading}) => (
  <div style={{display: 'flex', justifyContent: 'center', zIndex: 99, marginTop: 20}}>
    <div>
      <LoadMoreButton text={text} loading={loading} onClick={onClick} />
    </div>
  </div>
);

SeeMore.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
  loading: PropTypes.bool
};

export default SeeMore;
