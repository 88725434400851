import React, {useState} from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import SectionTitle from '../../../../shared/library/typography/section-title';
import PrivatePublicIndicator from '../../../../shared/library/private-public-indicator';
import {Query} from 'react-apollo';
import {stackApps} from '../../../../data/stack-profile/queries';
import Circular, {LARGE} from '../../../../shared/library/indicators/indeterminate/circular';
import {BASE_TEXT, WEIGHT} from '../../../../shared/style/typography';
import {WHITE, IRON, MAKO} from '../../../../shared/style/colors';
import Spinner, {BUTTON} from '../../../../shared/library/indicators/indeterminate/circular';
import StackAppsCard from '../../../../shared/library/cards/stack-apps/stack-apps-card';
import {TABLET} from '../../../../shared/style/breakpoints';
import NavAnchor from '../../../tool-profile/components/shared/nav-anchor';

const PrivPubContainer = glamorous.div({
  paddingTop: 80,
  marginTop: -40,
  marginBottom: 16,
  display: 'flex',
  alignItems: 'flex-end',
  '> div': {
    marginLeft: 15
  },
  '> h2': {
    marginBottom: 0
  }
});

const GridContainer = glamorous.div({
  display: 'grid',
  gridTemplateColumns: `repeat(3, 1fr)`,
  gridGap: 20,
  padding: '5px 0 10px',
  [TABLET]: {
    gridTemplateColumns: `repeat(1, 1fr)`
  }
});

const ViewMore = glamorous.button({
  ...BASE_TEXT,
  height: 32,
  border: `1px solid ${IRON}`,
  borderRadius: 100,
  background: WHITE,
  padding: '0 15px',
  fontSize: 9,
  lineHeight: 1.44,
  fontWeight: WEIGHT.BOLD,
  letterSpacing: 0.14,
  outline: 'none',
  width: 96,
  color: MAKO,
  zIndex: 1
});

const Center = glamorous.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 20
});

const Loader = glamorous.div({
  display: 'flex',
  justifyContent: 'center',
  margin: '100px 0'
});

const LoadMoreSpinner = glamorous(Spinner)({
  margin: 'auto'
});

const updateQuery = (prev, next) => {
  const checkLength =
    next.stackProfile && next.stackProfile.stackApps && next.stackProfile.stackApps.edges.length;
  if (checkLength) {
    next.stackProfile.stackApps.edges = [
      ...prev.stackProfile.stackApps.edges,
      ...next.stackProfile.stackApps.edges
    ];
    return next;
  } else {
    return prev;
  }
};

const StackApps = ({ownerSlug, stackSlug}) => {
  const [loadingMore, setLoadingMore] = useState(false);
  return (
    <>
      <NavAnchor id="apps" offset={110} />
      <Query
        query={stackApps}
        variables={{
          id: `${ownerSlug}/${stackSlug}`,
          first: 6,
          after: null
        }}
        notifyOnNetworkStatusChange={true}
      >
        {({loading, data, fetchMore}) => {
          const hasData = data && data.stackProfile && data.stackProfile.stackApps;

          if (loading && !hasData)
            return (
              <Loader>
                <Circular size={LARGE} />
              </Loader>
            );
          if (hasData && hasData.count !== 0) {
            const hasNextPage = hasData.pageInfo?.hasNextPage;
            const endCursor = hasData.pageInfo?.endCursor;
            return (
              <>
                <PrivPubContainer>
                  <SectionTitle>Apps</SectionTitle>
                  <PrivatePublicIndicator typeIndicator="BlueRoundLarge" />
                </PrivPubContainer>

                <GridContainer>
                  {hasData.edges.map(item => (
                    <StackAppsCard
                      key={item.node.id}
                      name={item.node.name}
                      item={item.node}
                      count={item.node.stacks.count}
                      iconContainerHeight={'65%'}
                    />
                  ))}
                </GridContainer>

                {hasNextPage && (
                  <Center>
                    <ViewMore
                      disabled={loadingMore}
                      onClick={() => {
                        setLoadingMore(true);
                        fetchMore({
                          variables: {
                            id: `${ownerSlug}/${stackSlug}`,
                            first: 6,
                            after: endCursor
                          },
                          updateQuery: (prev, {fetchMoreResult: next}) => {
                            setLoadingMore(false);
                            return updateQuery(prev, next);
                          }
                        });
                      }}
                    >
                      {loadingMore ? <LoadMoreSpinner size={BUTTON} /> : 'SHOW MORE'}
                    </ViewMore>
                  </Center>
                )}
              </>
            );
          } else return null;
        }}
      </Query>
    </>
  );
};

StackApps.propTypes = {
  ownerSlug: PropTypes.string,
  stackSlug: PropTypes.string
};

export default StackApps;
