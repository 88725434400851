import {useEffect, useState} from 'react';

const handleScroll = ({current: el}, setVisible) => {
  let isVisible = null;
  return () => {
    if (el && typeof el.getBoundingClientRect === 'function') {
      const {top, height} = el.getBoundingClientRect();
      const inViewport = top < window.innerHeight - height && top > -height;
      if (inViewport && isVisible !== true) {
        isVisible = true;
        setVisible(true);
      } else if (!inViewport && isVisible !== false) {
        isVisible = false;
        setVisible(false);
      }
    }
  };
};

export default navEl => {
  const [visible, setVisible] = useState(null);
  useEffect(() => {
    const cb = handleScroll(navEl, setVisible);
    window.addEventListener('scroll', cb, true);
    return () => window.removeEventListener('scroll', cb, true);
  }, [navEl.current]);
  return visible;
};
