import React from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import {Loader} from './loader';

const Container = glamorous.div({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridColumnGap: '20px'
});

const loader = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];

const MembersLoader = ({rows = 3}) => {
  return (
    <>
      {loader.slice(0, rows).map(item => (
        <Container key={`loader-${item}`}>
          <Loader w="100%" h={80} style={{margin: '18px 0'}} animate={true} />
          <Loader w="100%" h={80} style={{margin: '18px 0'}} animate={true} />
        </Container>
      ))}
    </>
  );
};

MembersLoader.propTypes = {
  rows: PropTypes.number
};

export default MembersLoader;
