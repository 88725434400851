import React, {useState, useContext} from 'react';
import glamorous from 'glamorous';
import PackageIcon from '../../../../shared/library/icons/package-icon.svg';
import {Query} from 'react-apollo';
import {packages} from '../../../../data/stack-profile/queries';
import {RouteContext} from '../../../../shared/enhancers/router-enhancer';
import {flattenEdges} from '../../../../shared/utils/graphql';
import Spinner, {BUTTON} from '../../../../shared/library/indicators/indeterminate/circular';
import {BASE_TEXT, WEIGHT} from '../../../../shared/style/typography';
import {PHONE} from '../../../../shared/style/breakpoints';
import {
  ASH,
  WHITE,
  CHARCOAL,
  ALABASTER,
  IRON,
  MAKO,
  FOCUS_BLUE,
  TARMAC
} from '../../../../shared/style/colors';
import {PACKAGES} from '../../../../shared/constants/packages';
import AdoptionStage from '../../../../shared/library/cards/stages';
import {PrivateModeContext} from '../../../../shared/enhancers/private-mode-enchancer';
import VersionStage from '../../../../shared/library/cards/stages/versions';

const LoadMoreSpinner = glamorous(Spinner)({
  marginRight: 'auto',
  marginLeft: 'auto'
});

const PackageWrapper = glamorous.div({
  boxShadow: `${WHITE} 0px 1px 0px 0px`,
  border: '1px solid #f0f0f0',
  borderRadius: 2,
  marginTop: 22
});

const Header = glamorous.div({
  ...BASE_TEXT,
  height: 35,
  backgroundColor: '#fafafa',
  padding: '9px 9px 9px 14px',
  fontSize: 12,
  fontWeight: 600,
  borderBottom: '1px solid #f0f0f0',
  [PHONE]: {
    display: 'flex',
    alignItems: 'center'
  },
  display: 'grid',
  gridTemplateColumns: '2fr 1fr',
  gridColumnGap: 20
});

const TitleItem = glamorous.span({
  fontWeight: 600,
  letterSpacing: 0.5,
  color: '#424242'
});

const List = glamorous.div({
  display: 'block'
});

const ListItem = glamorous.div({
  height: 43,
  padding: 8,
  borderBottom: '1px solid #f0f0f0',
  display: 'grid',
  gridTemplateColumns: '2fr 1fr',
  gridColumnGap: 20
});

const Package = glamorous.div({
  ...BASE_TEXT,
  fontSize: 14,
  fontWeight: 600,
  lineHeight: 'normal',
  color: CHARCOAL,
  textShadow: '0 0 4px rgba(0, 0, 0, 0)',
  display: 'flex',
  alignItems: 'center',
  '& .icon': {
    width: 20,
    height: 20,
    backgroundColor: WHITE,
    border: `1px solid ${ASH}`,
    marginRight: 9,
    borderRadius: 2,
    textAlign: 'center'
  },

  '& .name': {
    display: 'flex',
    marginRight: 5,
    ' a': {
      ...BASE_TEXT,
      fontSize: 14,
      fontWeight: 600,
      lineHeight: 'normal',
      color: CHARCOAL,
      textShadow: '0 0 4px rgba(0, 0, 0, 0)',
      '&:hover': {
        color: FOCUS_BLUE
      }
    }
  }
});

const Version = glamorous.div({
  ...BASE_TEXT,
  fontSize: 13,
  color: TARMAC
});

const Count = glamorous.span({
  backgroundColor: CHARCOAL,
  height: 18,
  width: 20,
  borderRadius: '50%',
  textAlign: 'center',
  fontSize: 11,
  fontWeight: 600,
  lineHeight: 1.27,
  color: '#fff',
  padding: '4px 5px',
  marginLeft: 6
});

const Center = glamorous.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: ALABASTER,
  borderRadius: 2,
  height: 48
});

const ViewMore = glamorous.button({
  ...BASE_TEXT,
  height: 28,
  border: `1px solid ${IRON}`,
  borderRadius: 100,
  background: WHITE,
  paddingLeft: 15,
  paddingRight: 15,
  fontSize: 9,
  lineHeight: 1.44,
  fontWeight: WEIGHT.BOLD,
  letterSpacing: 0.14,
  outline: 'none',
  width: 96,
  color: MAKO,
  zIndex: 1 // this puts the button above the negative margin for the section (used to nudge the anchor links position)
});

const ViewAll = glamorous.button({
  ...BASE_TEXT,
  height: 28,
  border: `1px solid ${IRON}`,
  borderRadius: 100,
  background: '#08a0ff',
  paddingLeft: 15,
  paddingRight: 15,
  marginLeft: 10,
  fontSize: 10,
  lineHeight: 1.44,
  fontWeight: WEIGHT.BOLD,
  letterSpacing: 0.14,
  outline: 'none',
  width: 96,
  color: WHITE,
  zIndex: 1
});

const PackageImage = glamorous.img(
  {
    objectFit: 'contain',
    cursor: 'pointer',
    marginLeft: -7,
    display: 'flex'
  },
  ({width, height}) => ({
    width: width,
    height: height
  })
);

const updateQuery = (prev, next) => {
  if (next.stackProfile.stackItems.edges.length) {
    next.stackProfile.stackItems.edges = [
      ...prev.stackProfile.stackItems.edges,
      ...next.stackProfile.stackItems.edges
    ];
    return next;
  } else {
    return prev;
  }
};

const Packages = () => {
  const {ownerSlug, stackSlug} = useContext(RouteContext);
  const privateMode = useContext(PrivateModeContext);
  const editPermission = privateMode && privateMode.permissions && privateMode.permissions.edit;
  const isEditStagesAllowed = privateMode?.adoptionStages?.some(stage => stage?.permissions?.edit);
  const [loadingMore, setLoadingMore] = useState(false);
  const [loadingAll, setLoadingAll] = useState(false);
  return (
    <Query
      query={packages}
      variables={{
        id: `${ownerSlug}/${stackSlug}`,
        companySlug: ownerSlug,
        first: 5,
        after: null
      }}
      errorPolicy="ignore"
    >
      {({loading, data, fetchMore}) => {
        const hasData = data && data.stackProfile && data.stackProfile.stackItems;
        if (loading && !hasData) return null;
        if (data && hasData) {
          const apiItems = flattenEdges(hasData).map(item => {
            return {
              ...item.tool,
              versionStage: item.versionStage && item.versionStage.name,
              versionNumber: item.versionNumber
            };
          });
          return (
            hasData.count > 0 && (
              <PackageWrapper>
                <Header>
                  <TitleItem>
                    PACKAGES <Count>{hasData.count}</Count>
                  </TitleItem>
                  <TitleItem>VERSION</TitleItem>
                </Header>
                <List>
                  {apiItems.map(item => (
                    <ListItem key={item.id}>
                      <Package>
                        <div className="icon">
                          <PackageIcon />
                        </div>
                        <div className="name">
                          <a href={`${item.path}`} target="_blank" rel="noopener noreferrer">
                            {item.name}
                          </a>
                        </div>
                        {'  /  '}
                        <a
                          href={`/${item.packageManager.slug}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <PackageImage
                            src={item.packageManager.miniImageUrl}
                            alt={item.name}
                            width={item ? PACKAGES[item.packageManager.slug].width : 76}
                            height={item ? PACKAGES[item.packageManager.slug].height : 19}
                          />
                        </a>
                        <AdoptionStage
                          name={
                            (item.versionStage && item.versionStage.name) ||
                            (item.adoptionStage && item.adoptionStage.name)
                          }
                          canEdit={editPermission || isEditStagesAllowed}
                          showAssignStage={false}
                          stage={
                            item.adoptionStage
                              ? {
                                  id: item.adoptionStage.id,
                                  slug: item.adoptionStage.slug,
                                  name: item.adoptionStage.name,
                                  context: item.versionStageContext
                                }
                              : null
                          }
                          toolSlug={item.slug}
                          tool={item}
                        />
                        {item.versionRules.totalCount ? <VersionStage /> : null}
                      </Package>
                      <Version>{item.versionNumber}</Version>
                    </ListItem>
                  ))}
                </List>
                {hasData.pageInfo.hasNextPage && (
                  <Center>
                    <ViewMore
                      disabled={loadingMore}
                      onClick={() => {
                        setLoadingMore(true);
                        fetchMore({
                          variables: {
                            first: 10,
                            after: hasData.pageInfo.endCursor
                          },
                          updateQuery: (prev, {fetchMoreResult: next}) => {
                            setLoadingMore(false);
                            return updateQuery(prev, next);
                          }
                        });
                      }}
                    >
                      {loadingMore ? <LoadMoreSpinner size={BUTTON} /> : 'SHOW MORE'}
                    </ViewMore>
                    <ViewAll
                      disabled={loadingAll}
                      onClick={() => {
                        setLoadingAll(true);
                        fetchMore({
                          variables: {
                            first: hasData.count,
                            after: hasData.pageInfo.endCursor
                          },
                          updateQuery: (prev, {fetchMoreResult: next}) => {
                            setLoadingAll(false);
                            return updateQuery(prev, next);
                          }
                        });
                      }}
                    >
                      {loadingAll ? <LoadMoreSpinner size={BUTTON} /> : 'SHOW ALL'}
                    </ViewAll>
                  </Center>
                )}
              </PackageWrapper>
            )
          );
        }
        return null;
      }}
    </Query>
  );
};

export default Packages;
