import gql from 'graphql-tag';

export const destroyStack = gql`
  mutation destroyStack($id: ID!) {
    destroyStack(id: $id)
  }
`;

export const dismissStackStrengthMeter = gql`
  mutation dismissStackStrengthMeter($stackIdentifier: String!) {
    dismissStackStrengthMeter(stackIdentifier: $stackIdentifier)
  }
`;
