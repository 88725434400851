import React from 'react';
import PropTypes from 'prop-types';
import {AnalyticsContext} from '../../../../shared/enhancers/analytics-enhancer';
import {getActiveService, defaultAnalyticsPayload} from '../../utils';

const StackProfileAnalyticsProvider = ({
  stack,
  stackOwnerType,
  activeServiceId,
  loading,
  children,
  toolItems
}) => {
  const activeService = getActiveService(activeServiceId, toolItems);
  const analyticsPayload = loading
    ? {}
    : defaultAnalyticsPayload(stack, stackOwnerType, activeService);
  return <AnalyticsContext.Provider value={analyticsPayload}>{children}</AnalyticsContext.Provider>;
};

StackProfileAnalyticsProvider.propTypes = {
  children: PropTypes.object,
  stack: PropTypes.object,
  stackOwnerType: PropTypes.string,
  activeServiceId: PropTypes.string,
  loading: PropTypes.bool,
  toolItems: PropTypes.object
};

export default StackProfileAnalyticsProvider;
