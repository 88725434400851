import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {WHITE} from '../../../../shared/style/colors';
import {BASE_TEXT} from '../../../../shared/style/typography';
import {ReferenceBox, PopperBox} from '../../popovers/hint/styles';
import glamorous from 'glamorous';
import TriangleIcon from '../../../../shared/library/icons/triangle.svg';
import LevelContainer from './level-container';
import {levels} from '../../../constants/stack-notification-levels';

const Container = glamorous.div({
  position: 'relative',
  display: 'flex',
  alignItems: 'center'
});

const Path = glamorous.div({
  width: 20,
  height: 20,
  margin: 0,
  padding: 0
});

const Shape = glamorous.div({
  width: 14,
  height: 10
});

const TriangleShape = glamorous.div({
  width: 8,
  height: 5,
  margin: '0 0 20px -6px',
  borderRadius: '0.5'
});

const AssignLevelContainer = glamorous.div({
  ...BASE_TEXT,
  display: 'flex',
  alignItems: 'center',
  height: 34,
  margin: '0 15px 0 10px',
  padding: '7px 2px 7px 8px',
  borderRadius: 2,
  border: 'solid 1px #e4e4e4',
  backgroundColor: WHITE,
  cursor: 'pointer',
  minWidth: 140
});

const Text = glamorous.div({
  ...BASE_TEXT,
  margin: '2px 0 3px 8px',
  textShadow: '0 0 4px rgba(0, 0, 0, 0)',
  fontSize: 11,
  fontWeight: 600,
  color: '#333333',
  width: `calc(100% - 8px - 30px)`
});

let Manager = null;
let Reference = null;
let Popper = null;

const StackNotification = ({notificationLevel, stackId}) => {
  const [showNotifyMenu, setNotifyMenu] = useState(false);
  const [ready, setReady] = useState(false);
  const [activeNotifyLevel, setActiveNotifyLevel] = useState(notificationLevel);
  const [container, setContainer] = useState();

  useEffect(() => {
    import(/* webpackChunkName: "react-popper" */ 'react-popper').then(module => {
      Manager = module.Manager;
      Reference = module.Reference;
      Popper = module.Popper;
      setReady(true);
    });
  }, []);

  useEffect(() => {
    const defaultLevel = levels && levels[3] && levels[3].slug;
    if (notificationLevel) setActiveNotifyLevel(notificationLevel);
    else setActiveNotifyLevel(defaultLevel);
  }, [notificationLevel]);

  const handleClick = () => {
    if (!showNotifyMenu) {
      document.addEventListener('click', handleDismiss, {capture: true, once: true});
    }
    setNotifyMenu(!showNotifyMenu);
  };

  const setNeWLevel = level => {
    setActiveNotifyLevel(level);
  };

  const handleDismiss = event => {
    if (!container.contains(event.target)) {
      setNotifyMenu(false);
    }
  };

  const getActiveLevelDetail = level => {
    const getLevel = levels && levels.find(item => item.slug === level);
    if (getLevel) {
      return {
        icon: getLevel.icons['active'],
        name: getLevel.name,
        slug: getLevel.slug
      };
    } else {
      //send default
      return {
        icon: levels[3].icons['active'],
        name: levels[3].name,
        slug: levels[3].slug
      };
    }
  };

  if (ready) {
    return (
      <Container innerRef={el => setContainer(el)}>
        <Manager>
          <Reference>
            {({ref}) => (
              <ReferenceBox innerRef={ref} onClick={handleClick}>
                <AssignLevelContainer>
                  <Path>
                    <Shape>{getActiveLevelDetail(activeNotifyLevel).icon}</Shape>
                  </Path>
                  <Text>{getActiveLevelDetail(activeNotifyLevel).name}</Text>
                  <TriangleShape>
                    <TriangleIcon />
                  </TriangleShape>
                </AssignLevelContainer>
              </ReferenceBox>
            )}
          </Reference>
          {showNotifyMenu && (
            <Popper
              placement="bottom"
              modifiers={{
                flip: {enabled: false},
                preventOverflow: {escapeWithReference: true}
              }}
            >
              {({ref, style, placement}) => (
                <PopperBox
                  innerRef={ref}
                  style={{
                    ...style,
                    width: 266,
                    margin: '21px 0 0 67px',
                    padding: '8px 0 4px 1px',
                    borderRadius: 2,
                    boxShadow: '0 11px 20px 0 rgba(0, 0, 0, 0.05)',
                    border: 'solid 1px #e1e1e1',
                    backgroundColor: WHITE,
                    transform: 'translate3d(-168px, 17px, 0px)'
                  }}
                  data-placement={placement}
                >
                  <LevelContainer
                    activeStackLevel={activeNotifyLevel}
                    stackId={stackId}
                    setNotifyMenu={setNotifyMenu}
                    levels={levels}
                    setLevel={setNeWLevel}
                  />
                </PopperBox>
              )}
            </Popper>
          )}
        </Manager>
      </Container>
    );
  } else return null;
};

StackNotification.propTypes = {
  notificationLevel: PropTypes.any,
  stackId: PropTypes.string
};

export default StackNotification;
