import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {compose} from 'react-apollo';
import glamorous from 'glamorous';
import * as clipboard from 'clipboard-polyfill';
import BaseModal from '../../../../../shared/library/modals/base/modal';
import {withPortal} from '../../../../../shared/library/modals/base/portal';
import TextInput from '../../../../../shared/library/inputs/text';
import SimpleButton from '../../../../../shared/library/buttons/base/simple';
import {CATHEDRAL} from '../../../../../shared/style/colors';
import TechStackIcon from '../../../../../shared/library/icons/tech-stack.svg';
import {MEDIUM} from '../../../../../shared/library/buttons/base/style';
import SucessMessage from '../../../../../shared/library/success-message/index';

const Container = glamorous.div({
  marginTop: 20
});

const CopyContainer = glamorous.div({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
  marginBottom: 10,
  ' div': {
    flexGrow: 2,
    marginRight: 15
  }
});

const Title = glamorous.p({
  textTransform: 'uppercase',
  color: CATHEDRAL,
  fontWeight: 'bold'
});

const StyledSimpleButton = glamorous(SimpleButton)({
  height: MEDIUM
});

const StyledTechStackIcon = glamorous(TechStackIcon)({
  borderRadius: 3
});

const copyStackUrl = async ({embedUrl, setCopySuccess}) => {
  try {
    await clipboard.writeText(embedUrl).then(() => setCopySuccess('Link copied  (⌘+V to paste)'));
  } catch (err) {
    // eslint-disable-next-line
    console.warn('could not copy stack badge url to clipboard', err);
  }
};

const StackBadge = ({onDismiss, name, path}) => {
  const [copySuccess, setCopySuccess] = useState('');
  const embedUrl = `[![StackShare](http://img.shields.io/badge/tech-stack-0690fa.svg?style=flat)](https://stackshare.io${path})`;
  return (
    <BaseModal title="Generate Stack Badge" width={450} onDismiss={onDismiss}>
      Link to the stack <strong>{name}</strong> in your READ ME
      <Container>
        <Title>Preview</Title>
        <StyledTechStackIcon />
      </Container>
      <Container>
        <Title>Markdown</Title>
        <CopyContainer>
          {!copySuccess && <TextInput readOnly={true} value={embedUrl} />}
          <SucessMessage message={copySuccess} />
          <StyledSimpleButton onClick={() => copyStackUrl({embedUrl, setCopySuccess})}>
            Copy
          </StyledSimpleButton>
        </CopyContainer>
      </Container>
    </BaseModal>
  );
};

StackBadge.propTypes = {
  onDismiss: PropTypes.func,
  name: PropTypes.string,
  path: PropTypes.string
};

export default compose(withPortal)(StackBadge);
