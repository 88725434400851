import React, {useState, useContext} from 'react';
import {compose} from 'react-apollo';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import QuestionIcon from '../../../../shared/library/icons/question.svg';
import SimpleButton from '../../../../shared/library/buttons/base/simple.jsx';
import {BASE_TEXT, WEIGHT} from '../../../../shared/style/typography';
import {ASH} from '../../../../shared/style/colors';
import QuestionModal from '../../../../bundles/stackups/components/questions-answers/question-modal';
import ConfirmationModal from './confirmation-modal';
import {NavigationContext} from '../../../../shared/enhancers/router-enhancer';
import {withSendAnalyticsEvent} from '../../../../shared/enhancers/analytics-enhancer';
import {SIGN_IN_PATH} from '../../../../shared/constants/paths';
import {withCurrentUser} from '../../../../shared/enhancers/current-user-enhancer';
import {STACKUPS_QUESTION_CLICK_CTA} from '../../../../bundles/stackups/constants/analytics';

const Container = glamorous.div({
  display: 'flex',
  padding: 20,
  alignItems: 'center',
  border: `1px solid ${ASH}`,
  borderRadius: 2
});

const StyledQuestionIcon = glamorous(QuestionIcon)({
  height: 57,
  width: 57,
  flexShrink: 0
});

const Copy = glamorous.div({
  ...BASE_TEXT,
  fontSize: 15,
  fontWeight: WEIGHT.BOLD,
  marginLeft: 20,
  width: 190
});

const StyledSimpleButton = glamorous(SimpleButton)({
  padding: 0,
  width: 120,
  borderRadius: 3,
  flexShrink: 0
});

const AskQuestionCta = ({tool, stack, currentUser, ownerName, sendAnalyticsEvent}) => {
  const content = `Want more information about this stack${
    tool ? ` or how ${tool.name} is used in it` : ``
  }?`;
  const navigate = useContext(NavigationContext);
  const [showQuestionModal, setShowQuestionModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const isCurrentUser = currentUser && !currentUser.loading;
  const userId = isCurrentUser ? currentUser.id : null;
  const toolId = tool ? tool.id : null;
  const identifier = `userId=${userId}&stackId=${stack.id}&toolId=${toolId}`;
  const subject = tool ? tool.name : 'this stack';
  const title = `Ask ${ownerName} about ${subject} (Beta)!`;

  return (
    <Container>
      <StyledQuestionIcon />
      <Copy>{content}</Copy>
      <StyledSimpleButton
        onClick={() => {
          sendAnalyticsEvent(STACKUPS_QUESTION_CLICK_CTA);
          setShowQuestionModal(true);
        }}
      >
        Ask a question
      </StyledSimpleButton>
      {showQuestionModal && (
        <QuestionModal
          userId={userId}
          title={title}
          stackupName={subject}
          stackupSlug={identifier}
          onDismissNoQuestion={() => setShowQuestionModal(false)}
          panelPosition={'middle'}
          onSubmit={() => {
            if (isCurrentUser) {
              setShowConfirmationModal(true);
            }
          }}
          onDismiss={() => {
            setShowQuestionModal(false);
            if (!isCurrentUser) {
              navigate(SIGN_IN_PATH);
            }
          }}
        />
      )}
      {showConfirmationModal && (
        <ConfirmationModal onDismiss={() => setShowConfirmationModal(false)} />
      )}
    </Container>
  );
};

AskQuestionCta.propTypes = {
  tool: PropTypes.object,
  stack: PropTypes.object,
  currentUser: PropTypes.object,
  ownerName: PropTypes.string,
  sendAnalyticsEvent: PropTypes.func
};

export default compose(
  withCurrentUser,
  withSendAnalyticsEvent
)(AskQuestionCta);
