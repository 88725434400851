import React, {useContext} from 'react';
import {Query} from 'react-apollo';
import glamorous from 'glamorous';
import {RouteContext} from '../../../shared/enhancers/router-enhancer';
import {stackTags} from '../../../data/stack-profile/queries';
import {TaggingContext, STACK} from '../../../shared/enhancers/all-tags';
import Circular, {LARGE} from '../../../shared/library/indicators/indeterminate/circular';

const Loader = glamorous.div({
  display: 'flex',
  justifyContent: 'center',
  margin: '100px 0'
});

const withStackTags = Component => props => {
  const {ownerSlug, stackSlug} = useContext(RouteContext);
  if (ownerSlug) {
    const id = `${ownerSlug}/${stackSlug}`;

    return (
      <Query query={stackTags} variables={{id}}>
        {({data, loading}) => {
          const hasData = data && data.stackProfile;
          let value = {
            tagsLoading: loading,
            tags: [],
            id: '',
            objectType: STACK
          };

          if (loading && !hasData) {
            return (
              <Loader>
                <Circular size={LARGE} />
              </Loader>
            );
          }

          if (!loading && hasData) {
            const tags = data.stackProfile.tags.edges.map(tag => tag.node.name);
            const objectId = data.stackProfile.id;

            value = {
              tagsLoading: loading,
              tags: tags,
              id: objectId,
              objectType: STACK
            };
          }
          return (
            <TaggingContext.Provider value={value}>
              <Component {...props} />
            </TaggingContext.Provider>
          );
        }}
      </Query>
    );
  }

  return <h1>Not Found</h1>;
};

export default withStackTags;
