import React from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import {BASE_TEXT, WEIGHT} from '../../../../shared/style/typography';
import {CONCRETE, GUNSMOKE, WHITE} from '../../../../shared/style/colors';

const Separator = glamorous.div({
  ...BASE_TEXT,
  fontSize: 12,
  lineHeight: '27px',
  fontWeight: WEIGHT.BOLD,
  letterSpacing: 1,
  color: GUNSMOKE,
  textTransform: 'uppercase',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexShrink: 0,
  flexGrow: 0,
  position: 'relative',
  marginTop: 10,
  marginBottom: 30,
  width: '100%',
  '>span': {
    background: WHITE,
    padding: `0 14px`,
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${CONCRETE}`,
    borderRadius: 27 / 2,
    zIndex: 1
  },
  '>div': {
    borderTop: `1px solid ${CONCRETE}`,
    height: 1,
    position: 'absolute',
    top: 27 / 2,
    width: '100%'
  }
});

const Icon = glamorous.img({
  width: 20,
  height: 20,
  borderRadius: 2,
  border: `solid 0.8px ${CONCRETE}`,
  marginRight: 10,
  marginTop: 3.5,
  marginBottom: 3.5
});

const SidebarSeparator = ({label, iconUrl}) => (
  <Separator>
    <div />
    <span>
      {iconUrl && <Icon src={iconUrl} />} {label}
    </span>
  </Separator>
);

SidebarSeparator.propTypes = {
  label: PropTypes.string,
  iconUrl: PropTypes.string
};

export default SidebarSeparator;
