import React, {Fragment, useContext} from 'react';
import PropTypes from 'prop-types';
import {Query} from 'react-apollo';
import SectionTitle from '../../../../shared/library/typography/section-title';
import {
  LIMIT_OTHER_STACKS,
  MAX_OTHER_STACKS,
  SP_CLICK_SHARE_TECH_STACK,
  SP_CLICKED_SEE_MORE_STACKS,
  SP_CLICKED_OTHER_STACK
} from '../../constants';
import StackCard from '../../../../shared/library/cards/stack/stack-card';
import SeeMoreButton from './see-more-button';
import {RouteContext} from '../../../../shared/enhancers/router-enhancer';
import {withSendAnalyticsEvent} from '../../../../shared/enhancers/analytics-enhancer';
import {otherStacks} from '../../../../data/stack-profile/queries';

const MoreStacks = ({
  List,
  ownerName,
  navigate,
  setActiveService,
  limitStacks,
  setLimitStacks,
  setLimitDecisions,
  sendAnalyticsEvent,
  full
}) => {
  const {ownerSlug, stackSlug} = useContext(RouteContext);

  return (
    <Query
      query={otherStacks}
      variables={{
        id: `${ownerSlug}/${stackSlug}`,
        limit: limitStacks ? LIMIT_OTHER_STACKS : MAX_OTHER_STACKS
      }}
    >
      {({loading, data}) => {
        if (loading && !data.stackProfile) return null;
        if (data && data.stackProfile.otherStacks.count > 0) {
          return (
            <Fragment>
              <SectionTitle>More stacks from {ownerName}</SectionTitle>
              <List columns={full ? 5 : 3}>
                {data.stackProfile.otherStacks.edges.map(({node: s}) => (
                  <StackCard
                    key={s.id}
                    websiteUrl={s.websiteUrl}
                    active={false}
                    canEdit={false}
                    companyStackSlug={`${ownerSlug}/${s.slug}`}
                    count={s.services.length}
                    name={s.name}
                    id={s.id}
                    services={s.services}
                    onClick={() => {
                      sendAnalyticsEvent(SP_CLICKED_OTHER_STACK);
                      setActiveService(null);
                      setLimitDecisions(true);
                      navigate(`/${ownerSlug}/${s.slug}`);
                    }}
                    redirectTo={() => null}
                    shareAnalyticsEventName={SP_CLICK_SHARE_TECH_STACK}
                    isPrivate={s.private}
                    repoStack={s.repoStack}
                  />
                ))}
              </List>
              {limitStacks && data.stackProfile.otherStacks.count > LIMIT_OTHER_STACKS && !loading && (
                <SeeMoreButton
                  onClick={() => {
                    sendAnalyticsEvent(SP_CLICKED_SEE_MORE_STACKS);
                    setLimitStacks(false);
                  }}
                />
              )}
              {!limitStacks && loading && <SeeMoreButton onClick={() => null} loading={loading} />}
            </Fragment>
          );
        }
        return null;
      }}
    </Query>
  );
};

MoreStacks.propTypes = {
  List: PropTypes.any,
  ownerName: PropTypes.string,
  navigate: PropTypes.func,
  setActiveService: PropTypes.func,
  limitStacks: PropTypes.bool,
  setLimitStacks: PropTypes.func,
  setLimitDecisions: PropTypes.func,
  sendAnalyticsEvent: PropTypes.func,
  full: PropTypes.bool
};

export default withSendAnalyticsEvent(MoreStacks);
