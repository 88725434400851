import React from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import Clock from '../icons/clock.svg';
import timestamp from '../../utils/timestamp';
import {TARMAC} from '../../style/colors';
import {BASE_TEXT, WEIGHT} from '../../style/typography';
import {PHONE} from '../../style/breakpoints';

const Wrapper = glamorous.div(
  {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    minWidth: 165,
    color: TARMAC,
    [PHONE]: {
      marginTop: 10,
      justifyContent: 'flex-start'
    }
  },
  ({customStyle}) => ({
    ...customStyle
  })
);

const Time = glamorous.div({
  ...BASE_TEXT
});

const Text = glamorous.div({
  ...BASE_TEXT,
  fontWeight: WEIGHT.BOLD,
  marginLeft: 5,
  marginRight: 3
});

const Timestamp = ({text, date, isDate, customStyle}) => {
  if (date.length !== 0) {
    return (
      <Wrapper customStyle={customStyle}>
        <Clock />
        <Text data-testid="updatedAt">{text}</Text>
        <Time data-testid="updatedAtDate">{timestamp(date, isDate)}</Time>
      </Wrapper>
    );
  }

  return null;
};

Timestamp.propTypes = {
  date: PropTypes.string,
  text: PropTypes.string,
  isDate: PropTypes.bool,
  customStyle: PropTypes.object
};

export default Timestamp;
