import React, {useState} from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import SectionTitle from '../../../../shared/library/typography/section-title';
import PrivateTeamCard from '../../../../shared/library/cards/private-teams';
import TechStackIcon from '../../../../shared/library/icons/tech-stacks-icon.svg';
import TeamIcon from '../../../../shared/library/icons/icon-team.svg';
import UtilitiesIcon from '../../../../shared/library/icons/utilities.svg';
import PrivatePublicIndicator from '../../../../shared/library/private-public-indicator';
import {Query} from 'react-apollo';
import {privateStackTeams} from '../../../../data/stack-profile/queries';
import Circular, {LARGE} from '../../../../shared/library/indicators/indeterminate/circular';
import {BASE_TEXT, WEIGHT} from '../../../../shared/style/typography';
import {WHITE, IRON, MAKO} from '../../../../shared/style/colors';
import Spinner, {BUTTON} from '../../../../shared/library/indicators/indeterminate/circular';
import NavAnchor from '../../../tool-profile/components/shared/nav-anchor';
import {ComponentContainer} from '../../../tool-profile/components/shared/styles';

const PrivPubContainer = glamorous.div({
  paddingTop: 30,
  marginBottom: 16,
  display: 'flex',
  alignItems: 'flex-end',
  '> div': {
    marginLeft: 15
  },
  '> h2': {
    marginBottom: 0
  }
});

const ViewMore = glamorous.button({
  ...BASE_TEXT,
  height: 32,
  border: `1px solid ${IRON}`,
  borderRadius: 100,
  background: WHITE,
  paddingLeft: 15,
  paddingRight: 15,
  fontSize: 9,
  lineHeight: 1.44,
  fontWeight: WEIGHT.BOLD,
  letterSpacing: 0.14,
  outline: 'none',
  width: 96,
  color: MAKO,
  zIndex: 1 // this puts the button above the negative margin for the section (used to nudge the anchor links position)
});

const Center = glamorous.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 20
});

const Loader = glamorous.div({
  display: 'flex',
  justifyContent: 'center',
  margin: '100px 0'
});

const LoadMoreSpinner = glamorous(Spinner)({
  marginRight: 'auto',
  marginLeft: 'auto'
});

const updateQuery = (prev, next) => {
  const checkLength =
    next.stackProfile &&
    next.stackProfile.privateTeams &&
    next.stackProfile.privateTeams.edges.length;
  if (checkLength) {
    next.stackProfile.privateTeams.edges = [
      ...prev.stackProfile.privateTeams.edges,
      ...next.stackProfile.privateTeams.edges
    ];
    return next;
  } else {
    return prev;
  }
};

const PrivateTeams = ({List, ownerSlug, stackSlug}) => {
  const [loadingMore, setLoadingMore] = useState(false);
  return (
    <ComponentContainer>
      <NavAnchor id="privateTeams" offset={110} />
      <Query
        query={privateStackTeams}
        variables={{
          id: `${ownerSlug}/${stackSlug}`,
          first: 10,
          after: null
        }}
        notifyOnNetworkStatusChange={true}
      >
        {({loading, data, fetchMore}) => {
          const privateTeams = data && data.stackProfile && data.stackProfile.privateTeams;
          if (loading && !privateTeams)
            return (
              <Loader>
                <Circular size={LARGE} />
              </Loader>
            );
          if (privateTeams && privateTeams.count !== 0) {
            const hasNextPage = privateTeams.pageInfo?.hasNextPage;
            const endCursor = privateTeams.pageInfo?.endCursor;
            return (
              <>
                <PrivPubContainer>
                  <SectionTitle>Teams</SectionTitle>
                  <PrivatePublicIndicator typeIndicator="BlueRoundLarge" />
                </PrivPubContainer>
                <List columns={2} gridGap={25}>
                  {privateTeams.edges.map(({node}) => {
                    const iconData = [
                      {
                        Icon: <TechStackIcon />,
                        count: node.privateStacksCount,
                        toolTip: 'Stacks'
                      },
                      {
                        Icon: <TeamIcon />,
                        count: node.membersCount,
                        toolTip: 'Members'
                      },
                      {
                        Icon: <UtilitiesIcon />,
                        count: node.privateToolsCount,
                        toolTip: 'Tools'
                      }
                    ];
                    return <PrivateTeamCard key={node.id} team={node} iconData={iconData} />;
                  })}
                </List>
                {hasNextPage && (
                  <Center>
                    <ViewMore
                      disabled={loadingMore}
                      onClick={() => {
                        setLoadingMore(true);
                        fetchMore({
                          variables: {
                            id: `${ownerSlug}/${stackSlug}`,
                            first: 20,
                            after: endCursor
                          },
                          updateQuery: (prev, {fetchMoreResult: next}) => {
                            setLoadingMore(false);
                            return updateQuery(prev, next);
                          }
                        });
                      }}
                    >
                      {loadingMore ? <LoadMoreSpinner size={BUTTON} /> : 'SHOW MORE'}
                    </ViewMore>
                  </Center>
                )}
              </>
            );
          }
          return null;
        }}
      </Query>
    </ComponentContainer>
  );
};

PrivateTeams.propTypes = {
  List: PropTypes.any,
  ownerSlug: PropTypes.string,
  stackSlug: PropTypes.string
};

export default PrivateTeams;
