import React, {useState} from 'react';
import PropTypes from 'prop-types';
import * as clipboard from 'clipboard-polyfill';
import {compose} from 'react-apollo';
import glamorous from 'glamorous';
import BaseModal from '../../../../../shared/library/modals/base/modal';
import {withPortal} from '../../../../../shared/library/modals/base/portal';
import TextInput from '../../../../../shared/library/inputs/text';
import SimpleButton from '../../../../../shared/library/buttons/base/simple';
import {ASH, WHITE, FOCUS_BLUE, BLACK, CATHEDRAL} from '../../../../../shared/style/colors';
import {MEDIUM} from '../../../../../shared/library/buttons/base/style';
import {reduceLayers} from '../../../../../shared/utils/reducers';
import {LAYERS, LAYER_NAMES} from '../../../../../shared/constants/layers';
import {ICONS} from '../../stack-widget';
import LightThemeIcon from '../../../../../shared/library/icons/embed/embed-grey-squares.svg';
import DarkThemeIcon from '../../../../../shared/library/icons/embed/embed-dark-squares.svg';
import {LIGHT, DARK} from '../../../../feed/constants/utils';
import SucessMessage from '../../../../../shared/library/success-message/index';

const Container = glamorous.div({
  marginTop: 20
});

const FlexContainer = glamorous.div({
  marginTop: 20,
  display: 'flex'
});

const CopyContainer = glamorous.div({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
  marginBottom: 10,
  ' div': {
    flexGrow: 2,
    marginRight: 15
  }
});

const Theme = glamorous.div({
  flexGrow: 1
});

const ThemeContainer = glamorous.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginRight: 30
});

const Layers = glamorous.div({
  flexGrow: 1
});

const Title = glamorous.p({
  textTransform: 'uppercase',
  color: CATHEDRAL,
  fontWeight: 'bold'
});

const StyledSimpleButton = glamorous(SimpleButton)({
  height: MEDIUM
});

const LayerContainer = glamorous.div({
  border: `1px solid ${ASH}`
});

const LayerButton = glamorous.div(
  {
    display: 'flex',
    alignItems: 'center',
    padding: 13,
    cursor: 'pointer',
    borderBottom: `1px solid ${ASH}`,
    ':last-child': {
      border: 'none'
    }
  },
  ({isActive}) => ({
    backgroundColor: isActive ? FOCUS_BLUE : WHITE,
    color: isActive ? WHITE : BLACK,
    ' svg': {
      stroke: isActive ? WHITE : ''
    }
  })
);

const Icon = glamorous.div({
  marginRight: 10,
  ' svg': {
    width: 17,
    height: 17
  }
});

const ThemeIcon = glamorous.div(
  {
    ' svg': {
      cursor: 'pointer',
      boxSizing: 'content-box'
    }
  },
  ({isActive}) => ({
    ' svg': {
      boxShadow: isActive ? `0px 0px 0px 3px ${FOCUS_BLUE}` : 'none',
      borderRadius: isActive ? 2 : 0
    }
  })
);

const Name = glamorous.div({
  marginRight: 5
});

const Count = glamorous.div({});

const onLayerClick = (
  layer,
  setLayerActive,
  layerActive,
  setDataLayers,
  dataLayers,
  index,
  setCopySuccess,
  copySuccess
) => {
  if (layerActive === layer || layerActive === true) {
    setLayerActive(null);
  } else {
    setLayerActive(layer);
  }
  if (dataLayers.includes(index)) {
    const valueToRemove = index;
    const filteredItems = dataLayers.filter(function(item) {
      return item !== valueToRemove;
    });
    setDataLayers(filteredItems);
  } else {
    setDataLayers([...dataLayers, index]);
  }
  resetCopySuccess(setCopySuccess, copySuccess);
};

const copyStackUrl = async ({stackEmbedUrl, setCopySuccess}) => {
  try {
    await clipboard
      .writeText(stackEmbedUrl)
      .then(() => setCopySuccess('Link copied  (⌘+V to paste)'));
  } catch (err) {
    // eslint-disable-next-line
    console.warn('could not copy embed url to clipboard', err);
  }
};

const onThemeClick = (setActiveTheme, activeTheme, theme, setCopySuccess, copySuccess) => {
  if (activeTheme === theme) {
    setActiveTheme(null);
  } else {
    setActiveTheme(theme);
  }
  resetCopySuccess(setCopySuccess, copySuccess);
};

const Layer = ({
  layer,
  icon,
  services,
  setDataLayers,
  dataLayers,
  index,
  setCopySuccess,
  copySuccess
}) => {
  const [layerActive, setLayerActive] = useState(true);
  return (
    <LayerButton
      onClick={() =>
        onLayerClick(
          layer,
          setLayerActive,
          layerActive,
          setDataLayers,
          dataLayers,
          index,
          setCopySuccess,
          copySuccess
        )
      }
      isActive={layerActive === layer || layerActive === true}
      index={index}
    >
      <Icon>{icon}</Icon>
      <Name>{LAYER_NAMES[layer]}</Name>
      <Count>{`(${services.length})`}</Count>
    </LayerButton>
  );
};

const resetCopySuccess = (setCopySuccess, copySuccess) => {
  if (copySuccess) {
    setCopySuccess('');
  }
};

const renderLayers = (
  layer,
  services,
  setDataLayers,
  dataLayers,
  index,
  setCopySuccess,
  copySuccess
) => {
  if (services && services.length > 0) {
    return (
      <Layer
        key={layer}
        icon={ICONS[layer]}
        layer={layer}
        services={services}
        setDataLayers={setDataLayers}
        dataLayers={dataLayers}
        index={index + 1}
        setCopySuccess={setCopySuccess}
        copySuccess={copySuccess}
      />
    );
  }
};

const StackEmbed = ({onDismiss, services, embedUrl}) => {
  const [copySuccess, setCopySuccess] = useState('');
  const [activeTheme, setActiveTheme] = useState(DARK);
  const [dataLayers, setDataLayers] = useState([1, 2, 3, 4]);
  const embedScript = `<script async src="https://cdn1.stackshare.io/javascripts/client-code.js" charset="utf-8"></script>`;
  const stackEmbedUrl =
    activeTheme && dataLayers.length
      ? `<a data-theme="${activeTheme}" data-layers="${dataLayers}" data-stack-embed="true" href="${embedUrl}"></a>${embedScript}`
      : 'Select a theme and some layers';
  const layers = reduceLayers(services);
  return (
    <BaseModal title="Stack Embed" width={700} onDismiss={onDismiss}>
      <FlexContainer>
        <Theme>
          <Title>Choose a Theme</Title>
          <ThemeContainer>
            <ThemeIcon
              onClick={() =>
                onThemeClick(setActiveTheme, activeTheme, DARK, setCopySuccess, copySuccess)
              }
              isActive={activeTheme === DARK}
            >
              <DarkThemeIcon />
            </ThemeIcon>
            <ThemeIcon
              onClick={() =>
                onThemeClick(setActiveTheme, activeTheme, LIGHT, setCopySuccess, copySuccess)
              }
              isActive={activeTheme === LIGHT}
            >
              <LightThemeIcon />
            </ThemeIcon>
          </ThemeContainer>
        </Theme>
        <Layers>
          <Title>Choose your Layers</Title>
          <LayerContainer>
            {LAYERS.map((layer, index) =>
              renderLayers(
                layer,
                layers[layer],
                setDataLayers,
                dataLayers,
                index,
                setCopySuccess,
                copySuccess
              )
            )}
          </LayerContainer>
        </Layers>
      </FlexContainer>
      <Container>
        <Title>Embed Code</Title>
        <CopyContainer>
          <SucessMessage message={copySuccess} />
          {!copySuccess && <TextInput readOnly={true} value={stackEmbedUrl} />}
          <StyledSimpleButton
            disabled={!activeTheme || dataLayers.length === 0}
            onClick={() => copyStackUrl({stackEmbedUrl, setCopySuccess})}
          >
            Copy
          </StyledSimpleButton>
        </CopyContainer>
      </Container>
    </BaseModal>
  );
};

StackEmbed.propTypes = {
  onDismiss: PropTypes.func,
  services: PropTypes.array,
  embedUrl: PropTypes.string
};

Layer.propTypes = {
  layer: PropTypes.string,
  services: PropTypes.array,
  icon: PropTypes.object,
  setDataLayers: PropTypes.func,
  dataLayers: PropTypes.array,
  index: PropTypes.number,
  setCopySuccess: PropTypes.func,
  copySuccess: PropTypes.string
};

export default compose(withPortal)(StackEmbed);
