import React from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import ServiceTile, {LARGE} from '../../../../shared/library/tiles/service';
import {formatCount} from '../../../../shared/utils/format';
import {CHARCOAL, DODGER_BLUE, TARMAC} from '../../../../shared/style/colors';
import {BASE_TEXT, FONT_FAMILY, WEIGHT} from '../../../../shared/style/typography';
import VerfiedIcon from '../../../../shared/library/icons/verified.svg';
// import FollowServiceButton from '../../../../shared/library/buttons/follow/follow-service-button';
// import UseThisButton from '../../../../shared/library/buttons/use-this';
import {withSendAnalyticsEvent} from '../../../../shared/enhancers/analytics-enhancer';
import {SP_CLICKED_DETAILS_PANEL_TOOL} from '../../constants';
// import {FEED_CLICK_TOOL_FOLLOW} from '../../../../bundles/feed/constants/analytics';
import AdoptionStage from '../../../../shared/library/cards/stages';
import IconExternalLink from '../../../../shared/library/icons/external-link.svg';
import {truncateText} from '../../../../shared/utils/truncate-text';

const Container = glamorous.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: 10,
  marginBottom: 5,
  paddingBottom: 10,
  width: '100%',
  flexShrink: 0
});

const DetailsPanel = glamorous.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingBottom: 13,
  marginBottom: 7,
  width: '100%'
});

const Name = glamorous.a({
  fontFamily: FONT_FAMILY,
  fontSize: 18,
  fontWeight: WEIGHT.BOLD,
  letterSpacing: 0.8,
  color: CHARCOAL,
  textDecoration: 'none',
  textAlign: 'center',
  ':hover': {
    color: CHARCOAL
  },
  marginTop: 5,
  marginBottom: 10
});

const Title = glamorous.div({
  fontFamily: FONT_FAMILY,
  fontSize: 12,
  lineHeight: 1.58,
  letterSpacing: 0.5,
  width: '90%',
  color: TARMAC,
  marginBottom: 10,
  textAlign: 'center'
});

const Stats = glamorous.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  width: '70%'
});

const Stat = glamorous(Name)(
  {
    fontSize: 20,
    textAlign: 'center',
    flex: 1
  },
  ({label}) => ({
    '::before': {
      content: label,
      fontSize: 12,
      fontWeight: WEIGHT.NORMAL,
      color: TARMAC,
      lineHeight: 1.58,
      letterSpacing: 0.5,
      display: 'block'
    }
  })
);

const AdoptionStageWrapper = glamorous.div({
  margin: '5px 0 0 -12px'
});

// const FlexMultiple = glamorous.div({
//   display: 'flex',
//   justifyContent: 'space-between',
//   width: '60%',
//   margin: '10px 0 20px',
//   ' > button': {
//     width: '48%',
//     marginRight: 10,
//     '&:last-child': {
//       margin: 0
//     }
//   }
// });

const ExternalLinkIcon = glamorous(IconExternalLink)({
  width: 16,
  height: 16,
  marginLeft: 5,
  marginTop: 2
});

const SourceLinkContainer = glamorous.div({
  display: 'flex',
  justifyContent: 'center',
  borderTop: `1px solid #F2F2F2`,
  borderBottom: `1px solid #F2F2F2`,
  width: '96%',
  height: 38,
  marginTop: 23
});

const Link = glamorous.a({
  display: 'flex',
  alignItems: 'center',
  ...BASE_TEXT,
  fontSize: 12,
  lineHeight: '16px',
  cursor: 'pointer',
  color: DODGER_BLUE,
  '&:hover': {
    color: DODGER_BLUE,
    textDecoration: 'underline'
  },
  '&:visited': {
    color: DODGER_BLUE
  }
});

const SourceText = glamorous.span({
  alignSelf: 'center',
  ...BASE_TEXT,
  fontSize: 12
});

const ServiceDetailsCard = ({service, canEdit, stagePermission, sendAnalyticsEvent}) => {
  const {
    // id,
    name,
    imageUrl,
    canonicalUrl,
    verified,
    title,
    votes,
    fans,
    stacks,
    // following,
    stage,
    sourceFilename,
    displayCheckRunUrl,
    slug
  } = service;

  return (
    <Container>
      <DetailsPanel>
        <ServiceTile
          size={LARGE}
          name={name}
          href={canonicalUrl}
          imageUrl={imageUrl}
          onClick={() => sendAnalyticsEvent(SP_CLICKED_DETAILS_PANEL_TOOL)}
          preventDefault={false}
        />
        <Name href={canonicalUrl} onClick={() => sendAnalyticsEvent(SP_CLICKED_DETAILS_PANEL_TOOL)}>
          {name} {verified && <VerfiedIcon />}
        </Name>
        <Title>{title}</Title>
        <AdoptionStageWrapper>
          {stagePermission && (
            <AdoptionStage
              name={stage && stage.name}
              canEdit={canEdit}
              stage={stage ? stage : null}
              toolSlug={slug}
              tool={service}
            />
          )}
        </AdoptionStageWrapper>

        {sourceFilename && (
          <SourceLinkContainer>
            <SourceText>Source: &nbsp;</SourceText>
            <Link
              href={displayCheckRunUrl}
              title={sourceFilename}
              target="_blank"
              rel="noreferrer noopener"
            >
              {truncateText(sourceFilename, 30, '...')} <ExternalLinkIcon />
            </Link>
          </SourceLinkContainer>
        )}
      </DetailsPanel>
      <Stats>
        <Stat label="Stacks">{formatCount(stacks)}</Stat>
        <Stat label="Fans">{formatCount(fans)}</Stat>
        <Stat label="Votes">{formatCount(votes)}</Stat>
      </Stats>
      {/* <FlexMultiple>
        <FollowServiceButton
          serviceId={id}
          following={following}
          onToggle={() => sendAnalyticsEvent(FEED_CLICK_TOOL_FOLLOW)}
        />
        <UseThisButton serviceId={id} />
      </FlexMultiple> */}
    </Container>
  );
};

ServiceDetailsCard.propTypes = {
  service: PropTypes.object,
  canEdit: PropTypes.bool,
  stagePermission: PropTypes.bool,
  sendAnalyticsEvent: PropTypes.func
};

export default withSendAnalyticsEvent(ServiceDetailsCard);
