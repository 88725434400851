import React, {useContext, Fragment} from 'react';
import {Query} from 'react-apollo';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';
import {stackDecisions, stackDecisionsForTool} from '../../../../data/stack-profile/queries';
import {RouteContext} from '../../../../shared/enhancers/router-enhancer';
import {PrivateModeContext} from '../../../../shared/enhancers/private-mode-enchancer';
import Circular, {LARGE} from '../../../../shared/library/indicators/indeterminate/circular';
import {MAX_CONNECTIONS, MAX_DECISIONS, SP_CLICKED_VIEW_MORE_DECISIONS} from '../../constants';
import {normaliseDecisions, normaliseDecisionsCount} from '../../utils';
import SidebarSeparator from '../../components/sidebar/separator';
import StackProfileDecisions from '../../components/shared/decisions';
import AskQuestionCta from '../../components/ask-question-cta';
import SeeMore from '../../components/shared/see-more-button';
import {MobileContext} from '../../../../shared/enhancers/mobile-enhancer';
import BaseSectionTitle from '../../../../shared/library/typography/section-title';
import {ASH} from '../../../../shared/style/colors';

const Spinner = glamorous.div({
  display: 'flex',
  justifyContent: 'center',
  marginTop: 100
});

const SidebarSection = glamorous.div({
  marginBottom: 20
});

const SectionTitle = glamorous(BaseSectionTitle)({
  paddingBottom: 13,
  borderBottom: `2px solid ${ASH}`,
  marginBottom: 20,
  paddingTop: 60
});

const StackDecisions = ({
  stack,
  limitDecisions,
  setLimitDecisions,
  activeService,
  isOwner,
  ownerName,
  sendAnalyticsEvent,
  setCount,
  activeServiceId
}) => {
  const {ownerSlug, stackSlug} = useContext(RouteContext);
  const privateMode = useContext(PrivateModeContext);
  const isMobile = useContext(MobileContext);

  return (
    <Query query={stackDecisions} variables={{id: `${ownerSlug}/${stackSlug}`}}>
      {({data: {stackProfile}, loading: loadingMain}) => {
        const count = normaliseDecisionsCount(stackProfile);
        setCount(count);
        let decisionsWithService = normaliseDecisions(stackProfile);
        let otherDecisions = [];
        return (
          <Query
            query={stackDecisionsForTool}
            variables={{
              id: `${ownerSlug}/${stackSlug}`,
              first: MAX_CONNECTIONS,
              filterByToolId: activeServiceId
            }}
            skip={activeServiceId === null}
          >
            {({data: byTool, loading: loadingByTool}) => {
              if (byTool && byTool.stackProfile) {
                decisionsWithService = normaliseDecisions(byTool.stackProfile);
              }
              return (
                <Query
                  query={stackDecisionsForTool}
                  variables={{
                    id: `${ownerSlug}/${stackSlug}`,
                    first: MAX_DECISIONS,
                    filterNotByToolId: activeServiceId
                  }}
                  skip={activeServiceId === null}
                >
                  {({data: notByTool, loading: loadingNotByTool}) => {
                    if (notByTool && notByTool.stackProfile) {
                      otherDecisions = normaliseDecisions(notByTool.stackProfile);
                    }
                    let decisionsLoading = [loadingMain, loadingByTool, loadingNotByTool];

                    return !isMobile ? (
                      decisionsLoading[0] || decisionsLoading[1] ? (
                        <Spinner>
                          <Circular size={LARGE} />
                        </Spinner>
                      ) : activeService ? (
                        <Fragment>
                          <SidebarSeparator
                            iconUrl={activeService.imageUrl}
                            label={`${
                              decisionsWithService.length === 0 ? 'no' : decisionsWithService.length
                            } decision${decisionsWithService.length === 1 ? '' : 's'} · ${
                              activeService.name
                            }`}
                          />
                          <StackProfileDecisions decisions={decisionsWithService} />
                          {!isOwner && !privateMode && (
                            <SidebarSection>
                              <AskQuestionCta
                                ownerName={ownerName}
                                tool={activeService}
                                stack={stack}
                              />
                            </SidebarSection>
                          )}
                          {otherDecisions.length > 0 &&
                            (limitDecisions ? (
                              <SeeMore
                                text="View more decisions"
                                onClick={() => {
                                  sendAnalyticsEvent(SP_CLICKED_VIEW_MORE_DECISIONS);
                                  setLimitDecisions(false);
                                }}
                              />
                            ) : (
                              <Fragment>
                                <SidebarSeparator label="other decisions" />
                                <StackProfileDecisions decisions={otherDecisions} />
                              </Fragment>
                            ))}
                        </Fragment>
                      ) : (
                        <Fragment>
                          <StackProfileDecisions decisions={decisionsWithService} />
                          {otherDecisions.length > 0 && limitDecisions && (
                            <SeeMore
                              text="View other decisions"
                              onClick={() => setLimitDecisions(false)}
                            />
                          )}
                        </Fragment>
                      )
                    ) : (
                      decisionsWithService.length > 0 && (
                        <StackProfileDecisions decisions={decisionsWithService}>
                          <SectionTitle id="decisions">Stack Decisions</SectionTitle>
                        </StackProfileDecisions>
                      )
                    );
                  }}
                </Query>
              );
            }}
          </Query>
        );
      }}
    </Query>
  );
};

StackDecisions.propTypes = {
  stack: PropTypes.object,
  limitDecisions: PropTypes.bool,
  setLimitDecisions: PropTypes.func,
  activeServiceId: PropTypes.string,
  activeService: PropTypes.object,
  isOwner: PropTypes.bool,
  ownerName: PropTypes.string,
  sendAnalyticsEvent: PropTypes.func,
  setCount: PropTypes.func
};

export default StackDecisions;
