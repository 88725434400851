import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import BaseModal from '../../../../shared/library/modals/base/modal.jsx';
import {withPortal} from '../../../../shared/library/modals/base/portal.jsx';
import QuestionBody from './question-body.jsx';
import {withSendAnalyticsEvent} from '../../../../shared/enhancers/analytics-enhancer.js';
import {STACKUPS_QUESTION_CLICK_CLOSE_MODAL} from '../../constants/analytics.js';
import {ID} from '../../../../shared/utils/graphql';
import Composer from '../../../../shared/library/composer';
import ComposerProvider from '../../../../shared/library/composer/state/provider';
import {compose} from 'react-apollo';
import {buildPostForStackupAskQuestion} from '../../utils';
import {PrivateModeContext} from '../../../../shared/enhancers/private-mode-enchancer';

const QuestionModal = ({
  onDismiss,
  onDismissNoQuestion,
  sendAnalyticsEvent,
  stackupName,
  stackupSlug,
  panelPosition,
  title,
  userId,
  onSubmit,
  tools
}) => {
  const privateMode = useContext(PrivateModeContext);

  // eslint-disable-next-line no-unused-vars
  const onModalDismiss = () => {
    sendAnalyticsEvent(STACKUPS_QUESTION_CLICK_CLOSE_MODAL, {
      userId,
      stackupName,
      stackupSlug,
      panelPosition
    });
    onDismissNoQuestion();
  };

  return (
    <BaseModal title={title} width={800} onDismiss={onDismiss}>
      {userId ? (
        <ComposerProvider
          post={buildPostForStackupAskQuestion(tools)}
          debug
          privateMode={privateMode}
        >
          <Composer onCancel={onDismiss} />
        </ComposerProvider>
      ) : (
        <QuestionBody
          onSubmit={onSubmit}
          onDismiss={onDismiss}
          stackupName={stackupName}
          stackupSlug={stackupSlug}
          userId={userId}
          panelPosition={panelPosition}
        />
      )}
    </BaseModal>
  );
};

QuestionModal.propTypes = {
  onDismiss: PropTypes.func,
  onDismissNoQuestion: PropTypes.func,
  sendAnalyticsEvent: PropTypes.func,
  stackupName: PropTypes.string,
  stackupSlug: PropTypes.string,
  panelPosition: PropTypes.string,
  title: PropTypes.string,
  userId: ID,
  onSubmit: PropTypes.func,
  tools: PropTypes.array
};

export default compose(
  withSendAnalyticsEvent,
  withPortal
)(QuestionModal);
