import React from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import PlusIcon from './icons/plus.svg';
import MinusIcon from './icons/minus.svg';
import ToolTile, {DESKTOP_TILE_SIZE, MOBILE_TILE_SIZE} from './tool-tile';
import {PHONE} from '../../../../shared/style/breakpoints';
import {BASE_TEXT, WEIGHT} from '../../../../shared/style/typography';
import {ASH, GUNSMOKE} from '../../../../shared/style/colors';
import {withSendAnalyticsEvent} from '../../../../shared/enhancers/analytics-enhancer';
import {SP_CLICKED_LAYER, ACTION_OPEN, ACTION_CLOSE} from '../../constants';
import {truncateText} from '../../../../shared/utils/truncate-text';

const MOBILE_SVG_SIZE = 16;
const DESKTOP_SVG_SIZE = 24;
const MOBILE_ICON_SIZE = 30;
const DESKTOP_ICON_SIZE = 45;

const MOBILE_TOGGLE_ICON_SIZE = 14;
const DESKTOP_TOGGLE_ICON_SIZE = 18;

const Container = glamorous.div({
  borderBottom: `1px solid ${ASH}`
});

const Header = glamorous.div({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  padding: 14,
  [PHONE]: {
    padding: 10
  }
});

const Icon = glamorous.div(({visible}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: `1px solid ${ASH}`,
  [PHONE]: {
    width: visible ? MOBILE_ICON_SIZE : 0,
    height: MOBILE_ICON_SIZE
  },
  width: visible ? DESKTOP_ICON_SIZE : 0,
  height: DESKTOP_ICON_SIZE,
  ' > svg': {
    [PHONE]: {
      width: visible ? MOBILE_SVG_SIZE : 0,
      height: MOBILE_SVG_SIZE
    },
    width: visible ? DESKTOP_SVG_SIZE : 0,
    height: DESKTOP_SVG_SIZE
  },
  visibility: visible ? 'visible' : 'hidden'
}));

const Name = glamorous.h3(({greyColor}) => ({
  ...BASE_TEXT,
  fontWeight: WEIGHT.BOLD,
  margin: 0,
  marginLeft: 8,
  fontSize: 14,
  color: greyColor ? GUNSMOKE : '#333333',
  [PHONE]: {
    fontSize: 12
  }
}));

const ToolCount = glamorous.span({
  ...BASE_TEXT,
  color: GUNSMOKE,
  fontSize: 12,
  marginLeft: 5
});

const Toggle = glamorous.div(({greyColor}) => ({
  display: 'flex',
  marginLeft: 'auto',
  paddingRight: 10,
  '> svg': {
    '> path': {
      fill: greyColor ? GUNSMOKE : '#565656'
    },
    height: DESKTOP_TOGGLE_ICON_SIZE,
    width: DESKTOP_TOGGLE_ICON_SIZE,
    [PHONE]: {
      height: MOBILE_TOGGLE_ICON_SIZE,
      width: MOBILE_TOGGLE_ICON_SIZE
    }
  }
}));

const Body = glamorous.div({
  display: 'grid',
  gridTemplateColumns: `repeat(auto-fill, minmax(${DESKTOP_TILE_SIZE}px, auto))`,
  gridGap: `12px 10px`,
  padding: '0 14px 14px 14px',
  [PHONE]: {
    gridTemplateColumns: `repeat(auto-fill, minmax(${MOBILE_TILE_SIZE}px, auto))`,
    padding: '0 10px 10px 10px'
  }
});

const Layer = ({
  layerIndex,
  toolsAvatars,
  services,
  activeServiceId,
  layerName,
  layerSlug,
  icon,
  setActiveService,
  openOrClose,
  setOpenOrClose,
  sendAnalyticsEvent
}) => {
  return (
    <Container>
      <Header
        onClick={() => {
          sendAnalyticsEvent(SP_CLICKED_LAYER, {
            action: openOrClose ? ACTION_CLOSE : ACTION_OPEN,
            layerName
          });
          setOpenOrClose(layerIndex);
        }}
      >
        <Icon visible={!!icon}>{icon}</Icon>
        <Name greyColor={layerSlug === 'none'}>{truncateText(layerName, 40, '...', true)}</Name>
        <ToolCount>{`(${services.length})`}</ToolCount>
        <Toggle greyColor={layerSlug === 'none'}>
          {openOrClose ? <MinusIcon /> : <PlusIcon />}
        </Toggle>
      </Header>
      {openOrClose && (
        <Body>
          {services.map((service, index) => (
            <ToolTile
              key={index}
              avatars={toolsAvatars[service.slug]}
              layerIndex={service.id}
              isActive={service.id === activeServiceId}
              service={service}
              setActiveService={setActiveService}
            />
          ))}
        </Body>
      )}
    </Container>
  );
};

Layer.propTypes = {
  toolsAvatars: PropTypes.object,
  services: PropTypes.array.isRequired,
  activeServiceId: PropTypes.string,
  layerName: PropTypes.string,
  layerSlug: PropTypes.string,
  layerIndex: PropTypes.number,
  icon: PropTypes.element,
  setActiveService: PropTypes.func,
  openOrClose: PropTypes.bool,
  setOpenOrClose: PropTypes.func,
  sendAnalyticsEvent: PropTypes.func
};

export default withSendAnalyticsEvent(Layer);
