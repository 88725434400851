import React, {Fragment, useState} from 'react';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';
import SectionTitle from '../../../../shared/library/typography/section-title';
import TeamMemberCard from '../../../../shared/library/cards/team-member';
import PrivatePublicIndicator from '../../../../shared/library/private-public-indicator';
import AddItem from '../../../../shared/library/buttons/add-item/index.jsx';
import {Query} from 'react-apollo';
import {stackRepoContributors} from '../../../../data/stack-profile/queries';
import {BASE_TEXT, WEIGHT} from '../../../../shared/style/typography';
import {WHITE, IRON, MAKO} from '../../../../shared/style/colors';
import Spinner, {BUTTON} from '../../../../shared/library/indicators/indeterminate/circular';
import MembersLoader from '../../../../shared/library/loaders/members';
import NavAnchor from '../../../tool-profile/components/shared/nav-anchor';
import {ComponentContainer} from '../../../tool-profile/components/shared/styles';

const SectionTitleDiv = glamorous.div({
  display: 'flex',
  marginTop: -40,
  paddingTop: 80,
  marginBottom: 16,
  alignItems: 'center',
  '> h2': {
    marginBottom: 0
  }
});

const ViewMore = glamorous.button({
  ...BASE_TEXT,
  height: 32,
  border: `1px solid ${IRON}`,
  borderRadius: 100,
  background: WHITE,
  paddingLeft: 15,
  paddingRight: 15,
  fontSize: 9,
  lineHeight: 1.44,
  fontWeight: WEIGHT.BOLD,
  letterSpacing: 0.14,
  outline: 'none',
  width: 96,
  color: MAKO,
  zIndex: 1 // this puts the button above the negative margin for the section (used to nudge the anchor links position)
});

const Center = glamorous.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 20
});

const LoadMoreSpinner = glamorous(Spinner)({
  marginRight: 'auto',
  marginLeft: 'auto'
});

const updateQuery = (prev, next) => {
  const checkLength =
    next.stackProfile &&
    next.stackProfile.contributors &&
    next.stackProfile.contributors.edges.length;
  if (checkLength) {
    next.stackProfile.contributors.edges = [
      ...prev.stackProfile.contributors.edges,
      ...next.stackProfile.contributors.edges
    ];
    return next;
  } else {
    return prev;
  }
};

const PrivateContributors = ({List, full, ownerSlug, stackSlug, isPrivate, isAdmin}) => {
  const [loadingMore, setLoadingMore] = useState(false);
  return (
    <ComponentContainer>
      <NavAnchor id="contributors" offset={110} />
      <Query
        query={stackRepoContributors}
        variables={{
          id: `${ownerSlug}/${stackSlug}`,
          first: 10,
          after: null
        }}
        notifyOnNetworkStatusChange={true}
      >
        {({loading, data, fetchMore}) => {
          const contributors = data && data.stackProfile && data.stackProfile.contributors;
          if (loading && !contributors) {
            return <MembersLoader />;
          }

          if (contributors && contributors.count !== 0) {
            const hasNextPage = contributors.pageInfo?.hasNextPage;
            const endCursor = contributors.pageInfo?.endCursor;
            return (
              <Fragment>
                <SectionTitleDiv>
                  <SectionTitle>Repo Contributors</SectionTitle>{' '}
                  {isPrivate && (
                    <>
                      <PrivatePublicIndicator typeIndicator="BlueRoundLarge" />{' '}
                      {isAdmin && (
                        <AddItem
                          addItemTitle="Add members"
                          addItemLink={`/companies/${ownerSlug}/members`}
                        />
                      )}
                    </>
                  )}
                </SectionTitleDiv>
                <List columns={full ? 3 : 2} gridGap={full ? 104 : 64}>
                  {contributors.edges.map(({node: member}) => (
                    <TeamMemberCard key={member.id} member={member} />
                  ))}
                </List>
                {hasNextPage && (
                  <Center>
                    <ViewMore
                      disabled={loadingMore}
                      onClick={() => {
                        setLoadingMore(true);
                        fetchMore({
                          variables: {
                            id: `${ownerSlug}/${stackSlug}`,
                            first: 20,
                            after: endCursor
                          },
                          updateQuery: (prev, {fetchMoreResult: next}) => {
                            setLoadingMore(false);
                            return updateQuery(prev, next);
                          }
                        });
                      }}
                    >
                      {loadingMore ? <LoadMoreSpinner size={BUTTON} /> : 'SHOW MORE'}
                    </ViewMore>
                  </Center>
                )}
              </Fragment>
            );
          }
          return null;
        }}
      </Query>
    </ComponentContainer>
  );
};

PrivateContributors.propTypes = {
  List: PropTypes.any,
  ownerSlug: PropTypes.string,
  stackSlug: PropTypes.string,
  isPrivate: PropTypes.bool,
  isAdmin: PropTypes.bool,
  full: PropTypes.bool
};

export default PrivateContributors;
