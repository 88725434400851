import React, {useRef, useContext} from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import {ASH, WHITE, TARMAC} from '../../../../shared/style/colors';
import CloseButton, {CLOSE, THEME_LIGHT} from '../../../../shared/library/buttons/circular';
import {RouteContext} from '../../../../shared/enhancers/router-enhancer';
import {withSendAnalyticsEvent} from '../../../../shared/enhancers/analytics-enhancer';
import {
  CONTENT_WIDTH,
  SIDEBAR_WIDTH,
  SP_CLICKED_NAV_ITEM,
  SP_CLICKED_DETAILS_PANEL_HEADER,
  ACTION_CLOSE,
  TAB_STACK,
  TAB_JOBS,
  TAB_TEAM,
  PRIVATE_TEAM,
  CONTRIBUTORS,
  APPS
} from '../../constants';
import {NAVBAR_HEIGHT, PAGE_WIDTH} from '../../../../shared/style/dimensions';
import {capitalize} from '../../../../shared/utils/string';
import DecisionIcon from '../../../../shared/library/icons/decision.svg';
import TechStackIcon from './icons/tech-stack-icon.svg';
import JobsIcon from './icons/jobs-icon.svg';
import VerifiedByIcon from './icons/verified-by-icon.svg';
import {BASE_TEXT} from '../../../../shared/style/typography';
import TeamIcon from '../../../../shared/library/icons/team-icon.svg';
import ApplicationIcon from '../../../../shared/library/icons/dropdown-icon-apps.svg';
import {ORG_STRUCTURE_MODES} from '../../../../shared/enhancers/organization-enhancer';

const Container = glamorous.div({
  ...BASE_TEXT,
  textDecoration: 'none',
  display: 'flex',
  width: PAGE_WIDTH,
  height: 57,
  background: WHITE,
  position: 'sticky',
  top: NAVBAR_HEIGHT,
  zIndex: 100
});

const NavColumn = glamorous.div({
  display: 'flex',
  width: CONTENT_WIDTH,
  alignItems: 'center',
  justifyContent: 'flex-start',
  marginLeft: 15,
  marginRight: -15
});

const SidebarColumn = glamorous.div({
  display: 'flex',
  width: SIDEBAR_WIDTH,
  borderLeft: `1px solid ${ASH}`,
  paddingLeft: 20,
  flexDirection: 'row',
  alignItems: 'center'
});

const NavItems = glamorous.div({
  '> a': {
    borderLeft: `1px solid ${ASH}`,
    marginRight: 30,
    paddingLeft: 30,
    '&:first-child': {
      border: 'none',
      paddingLeft: 0
    }
  },
  display: 'flex',
  alignItems: 'center'
});

const NavItem = glamorous.a({
  textDecoration: 'none',
  display: 'flex',
  alignItems: 'center',
  '>svg': {
    marginRight: 10
  },
  color: TARMAC
});

const SidebarTitle = glamorous.div({
  flexGrow: 1,
  display: 'flex',
  alignItems: 'center',
  fontSize: 15,
  letterSpacing: 0.2,
  '>svg': {
    marginRight: 10
  }
});

const StickyNav = ({
  showJobs,
  showTeam,
  showPrivateTeam,
  onHideSidebar,
  sendAnalyticsEvent,
  showContributors,
  showApps,
  orgStructureMode
}) => {
  const {hideDecisions} = useContext(RouteContext);
  const el = useRef(null);

  return (
    <Container innerRef={el}>
      <NavColumn>
        <NavItems>
          <NavItem
            href="#stack"
            onClick={() => sendAnalyticsEvent(SP_CLICKED_NAV_ITEM, {item_name: TAB_STACK})}
          >
            <TechStackIcon /> {capitalize(TAB_STACK)}
          </NavItem>
          {showJobs && (
            <NavItem
              href="#jobs"
              onClick={() => sendAnalyticsEvent(SP_CLICKED_NAV_ITEM, {item_name: TAB_JOBS})}
            >
              <JobsIcon /> {capitalize(TAB_JOBS)}
            </NavItem>
          )}
          {showApps && (
            <NavItem
              href="#apps"
              onClick={() => sendAnalyticsEvent(SP_CLICKED_NAV_ITEM, {item_name: APPS})}
            >
              <ApplicationIcon /> {capitalize(APPS)}
            </NavItem>
          )}
          {showPrivateTeam && orgStructureMode !== ORG_STRUCTURE_MODES.flexible && (
            <NavItem
              href="#privateTeams"
              onClick={() => sendAnalyticsEvent(SP_CLICKED_NAV_ITEM, {item_name: PRIVATE_TEAM})}
            >
              <TeamIcon /> {capitalize(PRIVATE_TEAM)}{' '}
            </NavItem>
          )}
          {showTeam && (
            <NavItem
              href="#team"
              onClick={() => sendAnalyticsEvent(SP_CLICKED_NAV_ITEM, {item_name: TAB_TEAM})}
            >
              <VerifiedByIcon /> {capitalize(TAB_TEAM)}
            </NavItem>
          )}
          {showContributors && (
            <NavItem
              href="#contributors"
              onClick={() => sendAnalyticsEvent(SP_CLICKED_NAV_ITEM, {item_name: TAB_TEAM})}
            >
              <VerifiedByIcon /> {capitalize(CONTRIBUTORS)}
            </NavItem>
          )}
        </NavItems>
      </NavColumn>
      {!hideDecisions && (
        <SidebarColumn>
          <SidebarTitle>
            <DecisionIcon />
            <strong>Stack</strong>&nbsp;Decisions
          </SidebarTitle>
          <CloseButton
            type={CLOSE}
            theme={THEME_LIGHT}
            onClick={() => {
              sendAnalyticsEvent(SP_CLICKED_DETAILS_PANEL_HEADER, {action: ACTION_CLOSE});
              onHideSidebar();
            }}
          />
        </SidebarColumn>
      )}
    </Container>
  );
};

StickyNav.propTypes = {
  showJobs: PropTypes.bool,
  showTeam: PropTypes.bool,
  showPrivateTeam: PropTypes.bool,
  onHideSidebar: PropTypes.func,
  sendAnalyticsEvent: PropTypes.func,
  showContributors: PropTypes.bool,
  showApps: PropTypes.bool,
  orgStructureMode: PropTypes.string
};

export default withSendAnalyticsEvent(StickyNav);
