import gql from 'graphql-tag';

export const watchStack = gql`
  mutation watchStack($level: String!, $stackId: ID!) {
    watchStack(level: $level, stackId: $stackId) {
      level
    }
  }
`;

export const watchAllCompanyStacks = gql`
  mutation watchAllCompanyStacks($level: String!) {
    watchAllCompanyStacks(level: $level) {
      __typename
      edges {
        node {
          websiteUrl
          id
          slug
          name
          path
          private
          stackWatchLevel {
            level
          }
        }
      }
    }
  }
`;
