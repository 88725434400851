import React from 'react';
import NotWatchingActive from '../library/icons/stack-notifications/notwatching-active.svg';
import WatchingActive from '../library/icons/stack-notifications/watching-active.svg';
import AdditionActive from '../library/icons/stack-notifications/additions-active.svg';
import StageOnlyActive from '../library/icons/stack-notifications/stages-active.svg';
import NotWatchingDisable from '../library/icons/stack-notifications/notwatching.svg';
import WatchingDisable from '../library/icons/stack-notifications/watching.svg';
import AdditionDisable from '../library/icons/stack-notifications/additions.svg';
import StageOnlyDisable from '../library/icons/stack-notifications/stages.svg';

export const DEFINED_LEVELS = {
  NOT_WATCHING: 'not-watching',
  WATCHING: 'watching',
  STAGES_ONLY: 'stages-only',
  ADDITIONS_REMOVALS_VERSION_ONLY: 'additions-removals-version-only'
};

export const levels = [
  {
    icons: {
      active: <NotWatchingActive />,
      disable: <NotWatchingDisable />
    },
    name: 'Not Watching',
    slug: DEFINED_LEVELS.NOT_WATCHING,
    description: 'no email notifications'
  },
  {
    icons: {
      active: <WatchingActive />,
      disable: <WatchingDisable />
    },
    name: 'Watching',
    slug: DEFINED_LEVELS.WATCHING,
    description: 'be notified of all tool additions/removals, version changes and stage changes'
  },
  {
    icons: {
      active: <StageOnlyActive />,
      disable: <StageOnlyDisable />
    },
    name: 'Stages Only',
    slug: DEFINED_LEVELS.STAGES_ONLY,
    description: 'be notified only when a tool moves from one stage to another.'
  },
  {
    icons: {
      active: <AdditionActive />,
      disable: <AdditionDisable />
    },
    name: 'Tool Changes',
    slug: DEFINED_LEVELS.ADDITIONS_REMOVALS_VERSION_ONLY,
    description:
      'be notified when a tool is added or removed to this stack and if a tool changes its version.'
  }
];
