import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {NavigationContext, RouteContext} from '../../../../shared/enhancers/router-enhancer';
import {PrivateModeContext} from '../../../../shared/enhancers/private-mode-enchancer';
import StackWidget from '../stack-widget';
import NavAnchor from '../../../tool-profile/components/shared/nav-anchor';

const StackItems = ({
  stack,
  isPrivate,
  showStages,
  setLimitDecisions,
  toolItems,
  activeServiceId,
  setActiveService
}) => {
  const {hideDecisions} = useContext(RouteContext);
  const privateMode = useContext(PrivateModeContext);
  const navigate = useContext(NavigationContext);

  return (
    <>
      <NavAnchor id="stack" offset={140} />
      <StackWidget
        toolsAvatars={stack.toolsAvatars}
        privateMode={privateMode}
        services={toolItems}
        activeServiceId={activeServiceId}
        navigate={navigate}
        hideDecisions={hideDecisions}
        showStages={showStages}
        isPrivate={isPrivate}
        permissions={stack.permissions}
        stackPath={stack.path}
        setActiveService={id => {
          if (hideDecisions) {
            navigate(stack.path);
          }
          setActiveService(id);
          setLimitDecisions(true);
        }}
      />
    </>
  );
};

StackItems.propTypes = {
  stack: PropTypes.object,
  isPrivate: PropTypes.bool,
  showStages: PropTypes.bool,
  toolItems: PropTypes.object,
  setLimitDecisions: PropTypes.func,
  activeServiceId: PropTypes.string,
  setActiveService: PropTypes.func
};

export default StackItems;
