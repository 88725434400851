import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import AppDataIcon from '../../../../shared/library/icons/layers/application-and-data.svg';
import UtilitiessIcon from '../../../../shared/library/icons/layers/utilities.svg';
import DevOpsIcon from '../../../../shared/library/icons/layers/dev-ops.svg';
import BusinessToolsIcon from '../../../../shared/library/icons/layers/business-tools.svg';
import {ASH, FOCUS_BLUE} from '../../../../shared/style/colors';
import Layer from './layer';
import {
  LAYERS,
  LAYER_APPLICATION_AND_DATA,
  LAYER_UTILITIES,
  LAYER_DEVOPS,
  LAYER_BUSINESS_TOOLS,
  LAYER_BUSINESS_TOOLS_WITH_UNDERSCORE,
  LAYER_APPLICATION_AND_DATA_WITH_UNDERSCORE
} from '../../../../shared/constants/layers';
import {ALPHA} from '../../../../shared/style/color-utils';
import {PHONE} from '../../../../shared/style/breakpoints';
import {BASE_TEXT} from '../../../../shared/style/typography';
import {featurePermissionCheck} from '../../../../shared/utils/feature-permission-check';
import {TOOL_ADOPTION_STAGE} from '../../../../shared/constants/features';
import EditIconDef from '../../../../shared/library/icons/edit_new.svg';

export const ICONS = {
  [LAYER_APPLICATION_AND_DATA]: <AppDataIcon />,
  [LAYER_APPLICATION_AND_DATA_WITH_UNDERSCORE]: <AppDataIcon />,
  [LAYER_UTILITIES]: <UtilitiessIcon />,
  [LAYER_DEVOPS]: <DevOpsIcon />,
  [LAYER_BUSINESS_TOOLS]: <BusinessToolsIcon />,
  [LAYER_BUSINESS_TOOLS_WITH_UNDERSCORE]: <BusinessToolsIcon />
};

const Container = glamorous.div({
  ...BASE_TEXT
});

const ToggleContainer = glamorous.div({
  display: 'flex',
  marginRight: 15,
  [PHONE]: {
    width: '100%'
  }
});

const HeaderContainer = glamorous.div(({showToolStages}) => ({
  borderRadius: 4,
  border: showToolStages ? 'solid 1px #e1e1e1' : 0,
  borderBottom: 0,
  backgroundColor: '#fbfbfb',
  padding: showToolStages ? 14 : 0,
  display: 'flex',
  alignItems: 'center'
}));

const Button = glamorous.a({
  fontSize: 11,
  color: FOCUS_BLUE,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  ':hover': {
    color: FOCUS_BLUE,
    textDecoration: 'underline'
  }
});

const ToggleStateContainer = glamorous.div(({selected}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: 'solid 1px #e4e4e4',
  borderRightWidth: 0,
  cursor: 'pointer',
  fontSize: 11,
  padding: '5px 0',
  minWidth: 108,
  backgroundColor: selected ? '#ffffff' : '#fafafa',
  fontWeight: selected ? 900 : 400,
  color: selected ? '#333333' : ALPHA('#333333', 0.5),
  ':last-child': {
    borderRightWidth: 1,
    borderTopRightRadius: 2,
    borderBottomRightRadius: 2
  },
  ':first-child': {
    borderTopLeftRadius: 2,
    borderBottomLeftRadius: 2
  },
  [PHONE]: {
    minWidth: 'auto',
    width: '50%'
  }
}));

const ToolContainer = glamorous.div({
  border: `1px solid ${ASH}`,
  boxShadow: `0 1px 0 0 ${ASH}`,
  borderRadius: 4,
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  ' > div:last-child': {
    borderBottom: 'none'
  }
});

const EditIcon = glamorous(EditIconDef)({
  marginRight: 8,
  height: 12,
  width: 'auto',
  '> path': {
    fill: FOCUS_BLUE
  }
});

const STAGE = 'stage';
const LAYER = 'layer';

const StackWidget = ({
  privateMode,
  navigate,
  stackPath,
  hideDecisions,
  isPrivate,
  showStages,
  toolsAvatars,
  services,
  activeServiceId,
  setActiveService,
  permissions
}) => {
  const [serviceList, setServiceList] = useState({[STAGE]: [], [LAYER]: []});
  const [listname, setListname] = useState(showStages ? STAGE : LAYER);
  // eslint-disable-next-line no-unused-vars
  const [showToolStages, setShowToolStages] = useState(
    isPrivate && featurePermissionCheck(privateMode, TOOL_ADOPTION_STAGE)
  );

  useEffect(() => {
    const fetchVal = state => {
      const segDataObj = {};
      const getAllStages = privateMode && privateMode.adoptionStages;

      services.map(tool => {
        const slug = tool[state]
          ? state === STAGE
            ? getAllStages.find(item => item.id === tool[state].slug)
              ? tool[state].slug
              : 'none'
            : tool[state].slug
          : 'none';
        const name = tool[state]
          ? state === STAGE
            ? getAllStages.find(item => item.name === tool[state].name)
              ? tool[state].name
              : 'No Tool Stage'
            : tool[state].name
          : 'No Tool Stage';

        if (segDataObj[slug]) {
          segDataObj[slug].services.push(tool);
        } else {
          segDataObj[slug] = {
            slug: slug,
            name: name,
            services: [{...tool}],
            open: name !== 'No Tool Stage'
          };
        }
      });

      const noneValue = segDataObj.none;
      delete segDataObj.none;

      let segDataList = Object.values(segDataObj);
      let finalList = [];

      if (state === LAYER) {
        LAYERS.map(layer => {
          segDataObj[layer] && finalList.push(segDataObj[layer]);
        });
      } else if (state === STAGE) {
        getAllStages.forEach(element => {
          let getStageData = segDataList.find(item => item.name === element.name);
          if (getStageData) finalList.push(getStageData);
        });
        if (noneValue) finalList.push(noneValue);
      }

      segDataList = [...finalList];
      return segDataList;
    };

    const obj = {};

    obj[LAYER] = fetchVal(LAYER);
    if (showToolStages) {
      const adoptionStageData = fetchVal(STAGE);
      obj[STAGE] = adoptionStageData;
    }

    setServiceList({...obj});
  }, [services, privateMode]);

  const setOpenOrClose = index => {
    const list = JSON.parse(JSON.stringify(serviceList));
    list[listname][index].open = !list[listname][index].open;
    setServiceList(list);
  };

  const canEditPermission = permissions && permissions.edit;

  return (
    services.length > 0 && (
      <Container>
        <HeaderContainer showToolStages={showToolStages}>
          {showToolStages && (
            <ToggleContainer>
              <ToggleStateContainer
                onClick={() => {
                  setListname(LAYER);
                  navigate(`${stackPath}${hideDecisions ? '/main' : ''}`);
                }}
                selected={!showStages}
              >
                Tool layers
              </ToggleStateContainer>
              <ToggleStateContainer
                onClick={() => {
                  setListname(STAGE);
                  navigate(`${stackPath}/stages${hideDecisions ? '/main' : ''}`);
                }}
                selected={showStages}
              >
                Tool stages
              </ToggleStateContainer>
            </ToggleContainer>
          )}
          {showToolStages && canEditPermission && (
            <Button href={`/stack-edit${stackPath}/tool-selection`}>
              <EditIcon />
              Edit Tools
            </Button>
          )}
        </HeaderContainer>
        <ToolContainer>
          {serviceList[listname].map((data, index) => (
            <Layer
              key={index}
              layerIndex={index}
              icon={ICONS[data.slug]}
              layerSlug={data.slug}
              layerName={data.name}
              services={data.services}
              toolsAvatars={toolsAvatars}
              activeServiceId={activeServiceId}
              setActiveService={setActiveService}
              openOrClose={data.open}
              setOpenOrClose={setOpenOrClose}
            />
          ))}
        </ToolContainer>
      </Container>
    )
  );
};

StackWidget.propTypes = {
  toolsAvatars: PropTypes.object,
  isPrivate: PropTypes.bool,
  privateMode: PropTypes.any,
  services: PropTypes.array.isRequired,
  activeServiceId: PropTypes.string,
  setActiveService: PropTypes.func,
  showStages: PropTypes.bool,
  hideDecisions: PropTypes.bool,
  navigate: PropTypes.func,
  stackPath: PropTypes.string,
  permissions: PropTypes.object
};

export default StackWidget;
