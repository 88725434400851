import gql from 'graphql-tag';
import {stackDecisionFields, adviceAnswers} from '../shared/fragments';
import {MAX_VISIBLE_JOBS, MAX_DECISIONS} from '../../bundles/stack-profile/constants';

export const strengthMeter = gql`
  query strengthMeter($id: String!) {
    stackProfile(identifier: $id) {
      id
      strengthMeter {
        dismissed
        percentComplete
        items {
          completed
          slug
        }
      }
    }
  }
`;

export const stackProfile = gql`
  query stackProfile($id: String!) {
    stackProfile(identifier: $id) {
      id
      slug
      name
      path
      websiteUrl
      private
      imageUrl
      description
      viewCount
      amIOwner
      toolsAvatars
      embedUrl
      updatedAt
      repoStack
      stackMatchPercentage
      stackWatchLevel {
        level
      }
      org {
        id
        name
        vcsProvider
        orgUrl
      }
      stackApps {
        count
      }
      contributorsCount
      privateTeamsCount
      ad {
        imageUrl
        targetUrl
        text
        title
        sponsorFeatured
        sponsorToolId
        ctaText
      }
      permissions {
        edit
        delete
      }
      owner {
        ... on Company {
          id
          imageUrl
          name
          slug
          path
          verified
          amIOwner
          following
          privateMode
          owners {
            id
          }
          team {
            count
            edges {
              node {
                id
              }
            }
          }
        }
        ... on User {
          id
          imageUrl
          displayName
          title
          username
        }
      }
    }
  }
`;

export const otherStacks = gql`
  query otherStacks($id: String!, $limit: Int!) {
    stackProfile(identifier: $id) {
      id
      otherStacks(first: $limit) {
        count
        edges {
          node {
            id
            slug
            websiteUrl
            name
            private
            repoStack
            services {
              id
              name
              slug
              title
              imageUrl
              layer {
                slug
              }
            }
          }
        }
      }
    }
  }
`;

export const stackDecisions = gql`
  query stackDecisions($id: String!) {
    stackProfile(identifier: $id) {
      id
      stackDecisions( first: ${MAX_DECISIONS} , currentStackOnly: true) {
        count
        edges {
          node {
            ...stackDecisionFields
            ...adviceAnswers
          }
        }
      }
    }
  }
  ${stackDecisionFields}
  ${adviceAnswers}
`;

export const stackDecisionsForTool = gql`
  query stackDecisionsForTool(
    $id: String!
    $first: Int!
    $filterByToolId: ID
    $filterNotByToolId: ID
  ) {
    stackProfile(identifier: $id) {
      id
      stackDecisions(
        first: $first
        filterByToolId: $filterByToolId
        filterNotByToolId: $filterNotByToolId
        currentStackOnly: true
      ) {
        edges {
          node {
            ...stackDecisionFields
          }
        }
      }
    }
  }
  ${stackDecisionFields}
`;

export const packages = gql`
  query packages($id: String!, $first: Int, $after: String, $companySlug: String!) {
    stackProfile(identifier: $id) {
      id
      stackItems(first: $first, after: $after, itemType: "Package") {
        count
        edges {
          node {
            tool {
              id
              name
              slug
              type
              imageUrl
              path
              canonicalUrl
              packageManager {
                miniImageUrl
                slug
              }
              toolType
              adoptionStageContext
              adoptionStage(companySlug: $companySlug) {
                id
                name
                slug
              }
              versionRules {
                totalCount
              }
            }
            versionStage {
              id
              slug: id
              name
            }
            versionStageContext
            versionNumber
            majorVersionNumber
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
`;

export const stackTags = gql`
  query stackTags($id: String!) {
    stackProfile(identifier: $id) {
      id
      tags {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`;

export const stackTeams = gql`
  query stackTeams($id: String!, $after: String, $first: Int) {
    stackProfile(identifier: $id) {
      id
      slug
      private
      owner {
        ... on Company {
          id
          imageUrl
          name
          slug
          privateMode
          team(after: $after, first: $first) {
            count
            pageInfo {
              hasNextPage
              endCursor
            }
            edges {
              node {
                id
                displayName
                username
                imageUrl
                title
                popularity
                path
                stacksCount
                favoritesCount
                votesCount
              }
            }
          }
        }
      }
    }
  }
`;

export const stackRepoContributors = gql`
  query stackRepoContributors($id: String!, $after: String, $first: Int) {
    stackProfile(identifier: $id) {
      id
      contributors(after: $after, first: $first) {
        count
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            id
            displayName
            username
            imageUrl
            title
            popularity
            path
            stacksCount
            favoritesCount
            votesCount
          }
        }
      }
    }
  }
`;

export const privateStackTeams = gql`
  query privateStackTeams($id: String!, $after: String, $first: Int) {
    stackProfile(identifier: $id) {
      id
      privateTeams(after: $after, first: $first) {
        count
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            id
            name
            description
            canonicalUrl
            privateStacksCount
            privateToolsCount(withoutPackages: true)
            membersCount
            imageUrl
          }
        }
      }
    }
  }
`;

export const stackApps = gql`
  query stackApps($id: String!, $after: String, $first: Int) {
    stackProfile(identifier: $id) {
      id
      slug
      name
      stackApps(after: $after, first: $first) {
        count
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            name
            id
            slug
            imageUrl
            canonicalUrl
            stacks {
              count
              edges {
                node {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const stackJobs = gql`
  query stackJobs($id: String!) {
    stackProfile(identifier: $id) {
      id
      owner {
        ... on Company {
          id
          name
          jobs(first: ${MAX_VISIBLE_JOBS}) {
            count
            edges {
              node {
                id
                angellistJobUrl
                title
                location
                services {
                  id
                  name
                  slug
                  title
                  verified
                  imageUrl
                  path
                  votes
                  fans
                  stacks
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const stackTools = gql`
  query stackTools($id: String!, $companySlug: String!) {
    stackProfile(identifier: $id) {
      id
      stackItems {
        count
        edges {
          node {
            tool {
              id
              name
              slug
              title
              imageUrl
              following
              verified
              votes
              fans
              stacks
              canonicalUrl
              latestVersionNumber
              layer {
                slug
                name
              }
              toolType
              adoptionStageContext
              adoptionStage(companySlug: $companySlug) {
                id
                slug: id
                name
              }
              versionRules {
                count
              }
            }
            sourceFilename
            displayCheckRunUrl
            versionStage {
              id
              slug: id
              name
            }
            versionStageContext
            versionNumber
          }
        }
      }
    }
  }
`;
