import React from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import {PHONE} from '../../../../shared/style/breakpoints';
import {BASE_TEXT, WEIGHT} from '../../../../shared/style/typography';
import {ALABASTER, WHITE, FOCUS_BLUE, CONCRETE} from '../../../../shared/style/colors';
import {withSendAnalyticsEvent} from '../../../../shared/enhancers/analytics-enhancer';
import {SP_CLICKED_TOOL_ICON, ACTION_SELECT, ACTION_DESELECT} from '../../constants';
import {truncateText} from '../../../../shared/utils/truncate-text';

export const DESKTOP_TILE_SIZE = 120;
export const MOBILE_TILE_SIZE = 80;

const DESKTOP_LOGO_WRAPPER_SIZE = 70;
const MOBILE_LOGO_WRAPPER_SIZE = 43;

const DESKTOP_LOGO_SIZE = 60;
const MOBILE_LOGO_SIZE = 38;

const DESKTOP_AUTHOR_SIZE = 24;
const MOBILE_AUTHOR_SIZE = 20;

const DESKTOP_AUTHOR_BORDER_SIZE = 2;
const MOBILE_AUTHOR_BORDER_SIZE = 1;

const Container = glamorous.div(
  {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    borderRadius: 6,
    height: DESKTOP_TILE_SIZE,
    width: DESKTOP_TILE_SIZE,
    backgroundColor: ALABASTER,
    borderWidth: 1,
    borderStyle: 'solid',
    [PHONE]: {
      borderRadius: 4,
      height: MOBILE_TILE_SIZE,
      width: MOBILE_TILE_SIZE
    },
    ':hover': {
      backgroundColor: WHITE,
      boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.31)'
    }
  },
  ({isActive}) => ({
    backgroundColor: isActive ? WHITE : ALABASTER,
    borderColor: isActive ? FOCUS_BLUE : WHITE
  })
);

const Logo = glamorous.img({
  borderRadius: 3,
  height: DESKTOP_LOGO_SIZE,
  width: DESKTOP_LOGO_SIZE,
  [PHONE]: {
    height: MOBILE_LOGO_SIZE,
    width: MOBILE_LOGO_SIZE
  }
});

const LogoWrapper = glamorous.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 4,
  height: DESKTOP_LOGO_WRAPPER_SIZE,
  width: DESKTOP_LOGO_WRAPPER_SIZE,
  backgroundColor: WHITE,
  [PHONE]: {
    borderRadius: 2,
    height: MOBILE_LOGO_WRAPPER_SIZE,
    width: MOBILE_LOGO_WRAPPER_SIZE
  }
});

const Name = glamorous.div({
  ...BASE_TEXT,
  marginTop: 3,
  fontWeight: WEIGHT.BOLD,
  fontSize: 13,
  textAlign: 'center',
  [PHONE]: {
    fontSize: 11
  }
});

const Authors = glamorous.div(
  {
    position: 'absolute',
    display: 'flex'
  },
  ({multipleDecisions}) => ({
    flexDirection: multipleDecisions ? 'row' : 'row-reverse',
    top: -DESKTOP_AUTHOR_SIZE / 2,
    right: multipleDecisions ? -DESKTOP_AUTHOR_SIZE / 6 : DESKTOP_AUTHOR_SIZE / 2,
    [PHONE]: {
      top: -MOBILE_AUTHOR_SIZE / 2,
      right: multipleDecisions ? -MOBILE_AUTHOR_SIZE / 10 : MOBILE_AUTHOR_SIZE / 2
    }
  })
);

const AuthorWrapper = glamorous.div(
  {
    height: DESKTOP_AUTHOR_SIZE,
    width: DESKTOP_AUTHOR_SIZE / 2,
    overflow: 'visible',
    [PHONE]: {
      height: MOBILE_AUTHOR_SIZE,
      width: MOBILE_AUTHOR_SIZE / 2
    }
  },
  ({multipleDecisions}) => ({
    zIndex: multipleDecisions ? 1 : 0
  })
);

const Author = glamorous.img({
  borderRadius: '50%',
  height: DESKTOP_AUTHOR_SIZE,
  width: DESKTOP_AUTHOR_SIZE,
  border: `${DESKTOP_AUTHOR_BORDER_SIZE}px solid ${WHITE}`,
  [PHONE]: {
    height: MOBILE_AUTHOR_SIZE,
    width: MOBILE_AUTHOR_SIZE,
    border: `${MOBILE_AUTHOR_BORDER_SIZE}px solid ${WHITE}`
  }
});

const AuthorCount = glamorous.div({
  ...BASE_TEXT,
  fontSize: 11,
  fontWeight: WEIGHT.BOLD,
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'flext-start',
  lineHeight: 1.72,
  justifyContent: 'center',
  letterSpacing: -1,
  marginLeft: DESKTOP_AUTHOR_SIZE / 4,
  color: WHITE,
  backgroundColor: CONCRETE,
  height: DESKTOP_AUTHOR_SIZE,
  width: DESKTOP_AUTHOR_SIZE,
  border: `${DESKTOP_AUTHOR_BORDER_SIZE}px solid ${WHITE}`,
  [PHONE]: {
    fontSize: 9,
    marginLeft: MOBILE_AUTHOR_SIZE / 2,
    height: MOBILE_AUTHOR_SIZE,
    width: MOBILE_AUTHOR_SIZE,
    border: `${MOBILE_AUTHOR_BORDER_SIZE}px solid ${WHITE}`
  }
});

const VersionAndStage = glamorous.div({
  ...BASE_TEXT,
  display: 'flex',
  fontSize: 11,
  color: '#343434'
});

const Info = glamorous.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

const renderAuthors = avatars => {
  if (avatars) {
    const {avatarsCount, avatarsUrls} = avatars;
    const multipleDecisions = avatarsCount > 2;

    return (
      <Authors multipleDecisions={multipleDecisions}>
        {avatarsUrls.map((url, index) => {
          if (avatarsCount <= 2 || (multipleDecisions && index === 0)) {
            return (
              <AuthorWrapper multipleDecisions={multipleDecisions} key={index}>
                <Author src={url} />
              </AuthorWrapper>
            );
          }
          if (multipleDecisions && index === 1) {
            return <AuthorCount key={1}>{`+${avatarsCount - 1}`}</AuthorCount>;
          }
        })}
      </Authors>
    );
  }
};

const ToolTile = ({
  avatars,
  isActive,
  setActiveService,
  service: {id, name, imageUrl, versionStage, versionNumber, adoptionStage},
  sendAnalyticsEvent
}) => {
  return (
    <Container
      isActive={isActive}
      onClick={() => {
        sendAnalyticsEvent(SP_CLICKED_TOOL_ICON, {
          action: isActive ? ACTION_DESELECT : ACTION_SELECT,
          toolName: name
        });
        setActiveService(isActive ? null : id);
      }}
    >
      <LogoWrapper>
        <Logo src={imageUrl} />
      </LogoWrapper>
      <Name>{truncateText(name, 16, '...', true)}</Name>
      <Info>
        {versionNumber && <VersionAndStage>{`v${versionNumber} `}</VersionAndStage>}
        {versionNumber && (adoptionStage || versionStage) && (
          <span style={{fontSize: 11}}>&nbsp;&#8211;&nbsp;</span>
        )}
        {versionStage && (
          <VersionAndStage>{truncateText(versionStage, 12, '...', true)}</VersionAndStage>
        )}
        {adoptionStage && (
          <VersionAndStage>{truncateText(adoptionStage.name, 12, '...', true)}</VersionAndStage>
        )}
      </Info>

      {renderAuthors(avatars)}
    </Container>
  );
};

ToolTile.propTypes = {
  avatars: PropTypes.object,
  service: PropTypes.object.isRequired,
  isActive: PropTypes.bool,
  setActiveService: PropTypes.func,
  sendAnalyticsEvent: PropTypes.func
};

export default withSendAnalyticsEvent(ToolTile);
