import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import DecisionIcon from '../../../../shared/library/icons/decision.svg';
import {BLACK, WHITE} from '../../../../shared/style/colors';
import {NavigationContext, RouteContext} from '../../../../shared/enhancers/router-enhancer';
import {withSendAnalyticsEvent} from '../../../../shared/enhancers/analytics-enhancer';
import {SP_CLICKED_DETAILS_PANEL_HEADER, ACTION_OPEN} from '../../constants';

const Icon = glamorous.div({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: 5,
  width: 23,
  '>svg': {
    transform: 'scale(1.3,1.3)',
    '>g': {
      fill: 'none',
      stroke: WHITE
    }
  }
});

const Container = glamorous.a({
  position: 'absolute',
  top: 184,
  right: 0,
  width: 50,
  height: 126,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  background: BLACK,
  borderTopLeftRadius: 6,
  borderBottomLeftRadius: 6,
  boxShadow: '0 2px 11px 0 rgba(0, 0, 0, 0.17)',
  zIndex: 1000,
  ':hover': {
    background: BLACK
  }
});

const Count = glamorous.div({
  fontSize: 16,
  letterSpacing: 0.4,
  color: WHITE
});

const Toggle = ({numDecisions, sendAnalyticsEvent}) => {
  const navigate = useContext(NavigationContext);
  const {ownerSlug, stackSlug} = useContext(RouteContext);
  const url = `/${ownerSlug}/${stackSlug}`;
  return (
    <Container
      href={url}
      onClick={e => {
        e && e.preventDefault();
        sendAnalyticsEvent(SP_CLICKED_DETAILS_PANEL_HEADER, {action: ACTION_OPEN});
        navigate(url);
      }}
    >
      <Icon>
        <DecisionIcon />
      </Icon>
      <Count>{numDecisions}</Count>
    </Container>
  );
};

Toggle.propTypes = {
  numDecisions: PropTypes.number,
  sendAnalyticsEvent: PropTypes.func
};

export default withSendAnalyticsEvent(Toggle);
