import React, {useContext, useState, useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import {ASH} from '../../../shared/style/colors';
import FixedNav from '../components/nav/fixed-nav';
import StickyNav from '../components/nav/sticky-nav';
import {truncateText} from '../../../shared/utils/truncate-text';
import {CONTENT_WIDTH, SIDEBAR_WIDTH, STACK_TYPE_COMPANY} from '../constants';
import {NAVBAR_HEIGHT, PAGE_WIDTH} from '../../../shared/style/dimensions';
import {NavigationContext, RouteContext} from '../../../shared/enhancers/router-enhancer';
import {withSendAnalyticsEvent} from '../../../shared/enhancers/analytics-enhancer';
import SidebarToggle from '../components/sidebar/toggle';
import {
  normaliseOwnerMeta,
  refetchQueriesForOwnerType,
  normaliseTeamMember,
  getActiveService
} from '../utils';
import Circular, {LARGE} from '../../../shared/library/indicators/indeterminate/circular';
import MoreStacks from '../components/shared/more-stacks';
import Packages from '../components/shared/packages';
import Team from '../components/shared/team';
import PrivateTeams from '../components/shared/private-teams';
import AskQuestionCta from '../components/ask-question-cta';
import {SigninDesktopModal} from '../../../shared/library/modals/signin';
import ServiceDetailsCard from '../components/cards/service-details-card';
// import Advert from '../../../shared/library/advert';
import {SLIM} from '../../../shared/library/advert/themes';
import StackMatchCard, {ROW} from '../../../shared/library/cards/stack-match';
import {compose} from 'react-apollo';
import ComposerProvider from '../../../shared/library/composer/state/provider';
import Composer from '../../../shared/library/composer';
import {PrivateModeContext} from '../../../shared/enhancers/private-mode-enchancer';
import {isPrivateMember} from '../../../shared/utils/is-private-member';
import PrivateContributors from '../components/shared/private-contributors';
import {
  STACK_NOTIFICATION,
  STACK_TAGS,
  TOOL_ADOPTION_STAGE
} from '../../../shared/constants/features';
import {featurePermissionCheck} from '../../../shared/utils/feature-permission-check';
// import {planPermissionCheck} from '../../../shared/utils/plan-permission-check';
// import {PLANS} from '../../private-payment/constants';
import SsoGithubCta from '../../../shared/library/cards/sso-github';
import {ApolloContext} from '../../../shared/enhancers/graphql-enhancer';
import StackItems from '../components/shared/stack-items';
import StackDecisions from '../components/shared/stack-decisions';
import StackApps from '../components/shared/stack-apps';
import {
  ORG_STRUCTURE_MODES,
  OrganizationContext
} from '../../../shared/enhancers/organization-enhancer';
// import {AD_IMPRESSION} from '../../../shared/constants/analytics';

const Page = glamorous.div({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column'
});

const Spinner = glamorous.div({
  display: 'flex',
  justifyContent: 'center',
  marginTop: 100
});

const Divider = glamorous.hr(
  {
    borderTop: `1px solid ${ASH}`,
    display: 'flex',
    width: '100%',
    margin: 0,
    zIndex: 100
  },
  ({sticky}) =>
    typeof sticky === 'undefined'
      ? null
      : {
          position: 'sticky',
          top: sticky + NAVBAR_HEIGHT
        }
);

const Content = glamorous.div({
  display: 'flex',
  width: PAGE_WIDTH,
  height: '100%'
});

const Main = glamorous.div(
  {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: CONTENT_WIDTH,
    paddingTop: 20,
    paddingRight: 20,
    paddingBottom: 50,
    '>h2': {
      paddingTop: 80,
      marginTop: -40
    }
  },
  ({full}) => ({width: full ? PAGE_WIDTH : CONTENT_WIDTH})
);

const Sidebar = glamorous.div({
  display: 'flex',
  flexDirection: 'column',
  borderLeft: `1px solid ${ASH}`,
  width: SIDEBAR_WIDTH
});

const SidebarContent = glamorous.div({
  display: 'flex',
  position: 'sticky',
  flexDirection: 'column',
  height: 'calc(100vh - 57px)',
  padding: 20,
  paddingRight: 0,
  overflow: 'scroll',
  top: 57 + NAVBAR_HEIGHT,
  paddingBottom: 100
});

const TeamCards = glamorous.div(
  {
    display: 'grid',
    '>*': {
      width: 300
    }
  },
  ({columns, gridGap}) => ({
    gridTemplateColumns: `repeat(${columns}, 1fr)`,
    gridGap: `20px ${gridGap}px`
  })
);

const StackCards = glamorous.div(
  {
    display: 'grid',
    gridGap: '24px'
  },
  ({columns}) => ({
    gridTemplateColumns: `repeat(${columns}, 1fr)`
  })
);

const StackMatch = glamorous.div({
  marginTop: 20
});

const SidebarSection = glamorous.div({
  marginBottom: 20
});

const sponsorPayload = stack => {
  const {ad} = stack;
  if (ad) {
    const sponsor = {
      image_url: ad.imageUrl,
      name: ad.title,
      service_id: ad.sponsorToolId,
      featured: ad.sponsorFeatured,
      text: ad.text,
      url: ad.targetUrl,
      cta_text: ad.ctaText,
      theme: SLIM
    };
    return sponsor;
  }
};

const StackProfile = ({
  loading,
  stack,
  stackOwnerType,
  currentUser,
  limitDecisions,
  setLimitDecisions,
  sendAnalyticsEvent,
  toolItems,
  activeServiceId,
  setActiveService
}) => {
  const privateMode = useContext(PrivateModeContext);
  const navigate = useContext(NavigationContext);
  const client = useContext(ApolloContext);
  const {ownerSlug, stackSlug, hideDecisions, showStages, signin} = useContext(RouteContext);
  const [limitStacks, setLimitStacks] = useState(true);
  const sidebarRef = useRef(null);
  const questionCtaRef = useRef(null);
  const editPermission = privateMode && privateMode.permissions && privateMode.permissions.edit;
  const isEditStagesAllowed = privateMode?.adoptionStages?.some(stage => stage?.permissions?.edit);
  const stagePermission = featurePermissionCheck(privateMode, TOOL_ADOPTION_STAGE);
  // const planPermission = planPermissionCheck(privateMode, PLANS.FREE);
  const companies =
    currentUser &&
    currentUser.companies &&
    currentUser.companies.filter(comp => comp.slug === ownerSlug);
  const isUserImpersonated = currentUser && currentUser.impersonated;
  const isAdmin = companies && companies.length > 0 && companies[0].myRole === 'admin';
  const showSsoGithubCta = currentUser && currentUser.shouldForceVcsConnection;
  const forcedVcsProvider = privateMode && privateMode.forcedVcsProvider;
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (window && typeof window.scrollTo === 'function') {
      window.scrollTo(0, 0);
    }
  }, [ownerSlug, stackSlug]);

  useEffect(() => {
    if (!loading && !hideDecisions) {
      const payload = sponsorPayload(stack);
      delete payload.service_id;
      payload.serviceId = stack.ad.sponsorToolId;
    }
  }, [ownerSlug, stackSlug, hideDecisions, loading]);

  useEffect(() => {
    if (sidebarRef.current && typeof sidebarRef.current.scrollTo === 'function') {
      sidebarRef.current.scrollTo(0, 0);
    }
  }, [activeServiceId]);

  if (loading) {
    return (
      <Spinner>
        <Circular size={LARGE} />
      </Spinner>
    );
  }

  const ownerMeta = normaliseOwnerMeta(stack, stackOwnerType);
  const hasTeam = stack.owner.team && stack.owner.team.count > 0;
  const hasPrivateContributors = stack.private && stack.contributorsCount > 0;
  const hasOwner = stack.owner.owners ? stack.owner.owners.length > 0 : false;
  const isPrivateCompanyStack = stack?.owner?.privateMode;
  const teamMember = normaliseTeamMember(stack.owner, currentUser, stackOwnerType);
  const activeService = getActiveService(activeServiceId, toolItems);
  const refetchQueries = refetchQueriesForOwnerType(ownerSlug, stackSlug);
  const matchPercentage = stack.stackMatchPercentage;
  const showMatchPercentage =
    matchPercentage !== null && stackOwnerType === STACK_TYPE_COMPANY && !privateMode;
  const isVerifiedStack = stack && stack.owner.verified;
  const isOwner = stackOwnerType === STACK_TYPE_COMPANY ? stack.owner.amIOwner : stack.amIOwner;
  const isPrivate = isPrivateMember(stack.owner.id, privateMode);
  const showPrivateTeam =
    stack.private &&
    isPrivate &&
    stackOwnerType === STACK_TYPE_COMPANY &&
    stack.privateTeamsCount > 0;
  const showApps = stack && stack.stackApps && stack.stackApps.count > 0;
  const truncatedDescription =
    stack.description && stack.description.length < 160
      ? stack.description
      : truncateText(stack.description || '', 160, '...');
  const tagsPermission = featurePermissionCheck(privateMode, STACK_TAGS);
  const stackNotificationPermission = featurePermissionCheck(privateMode, STACK_NOTIFICATION);
  const {orgStructureMode} = useContext(OrganizationContext);

  return (
    <>
      {showSsoGithubCta ? (
        <Page>
          <SsoGithubCta client={client} forcedVcsProvider={forcedVcsProvider} />
        </Page>
      ) : (
        <Page>
          <FixedNav
            teamMember={teamMember}
            type={stackOwnerType}
            {...ownerMeta}
            id={stack.id}
            name={stack.name}
            description={truncatedDescription}
            logoUrl={stack.imageUrl}
            viewCount={stack.viewCount}
            permissions={stack.permissions}
            slug={stack.slug}
            hasOwner={hasOwner}
            services={toolItems}
            isOwner={isOwner}
            embedUrl={stack.embedUrl}
            isVerifiedStack={isVerifiedStack}
            owner={stack.owner}
            isCompany={stackOwnerType === STACK_TYPE_COMPANY}
            path={stack.path}
            websiteUrl={stack.websiteUrl}
            privateStack={stack.private}
            showTags={
              stack.private && tagsPermission && stack.owner && stack.owner.__typename === 'Company'
            }
            isPrivate={stack.private}
            notificationLevel={stack.stackWatchLevel && stack.stackWatchLevel.level}
            stackNotificationPermission={stackNotificationPermission}
            org={stack.org}
            updatedAt={stack.updatedAt}
            repoStack={stack.repoStack}
          />
          <Divider />
          <StickyNav
            type={stackOwnerType}
            {...ownerMeta}
            name={stack.name}
            logoUrl={stack.imageUrl}
            showTeam={
              !stack.private && hasTeam && !hasPrivateContributors && !isPrivateCompanyStack
            }
            showContributors={privateMode && hasPrivateContributors}
            showPrivateTeam={showPrivateTeam}
            showApps={showApps}
            isVerifiedStack={isVerifiedStack}
            orgStructureMode={orgStructureMode}
            onHideSidebar={() => {
              setLimitDecisions(true);
              navigate(`${stack.path}${showStages ? '/stages' : ''}/main`);
            }}
            isCompany={stackOwnerType === STACK_TYPE_COMPANY}
          />
          <Divider sticky={57} />
          <Content>
            <Main full={hideDecisions}>
              <StackItems
                stack={stack}
                activeServiceId={activeServiceId}
                hideDecisions={hideDecisions}
                showStages={showStages}
                isPrivate={isPrivate}
                setLimitDecisions={setLimitDecisions}
                setActiveService={setActiveService}
                toolItems={toolItems}
              />

              <Packages />
              {!privateMode && (
                <MoreStacks
                  List={StackCards}
                  ownerName={ownerMeta.ownerName}
                  navigate={navigate}
                  setActiveService={setActiveService}
                  limitStacks={limitStacks}
                  setLimitStacks={setLimitStacks}
                  setLimitDecisions={setLimitDecisions}
                  full={hideDecisions}
                />
              )}

              {showMatchPercentage && (
                <StackMatch>
                  <StackMatchCard
                    matchPercentage={matchPercentage}
                    theme={ROW}
                    subject={ownerMeta.ownerName}
                  />
                </StackMatch>
              )}
              {showApps && <StackApps ownerSlug={ownerSlug} stackSlug={stackSlug} />}
              {showPrivateTeam && orgStructureMode !== ORG_STRUCTURE_MODES.flexible && (
                <PrivateTeams List={TeamCards} ownerSlug={ownerSlug} stackSlug={stackSlug} />
              )}
              {/*this is members although its named teams*/}
              {hasTeam && !hasPrivateContributors && !isPrivateCompanyStack && (
                <Team
                  List={TeamCards}
                  full={hideDecisions}
                  ownerSlug={ownerSlug}
                  stackSlug={stackSlug}
                  isPrivate={isPrivate && stack.private}
                  isAdmin={isAdmin}
                />
              )}
              {hasPrivateContributors && privateMode && (
                <PrivateContributors
                  List={TeamCards}
                  full={hideDecisions}
                  ownerSlug={ownerSlug}
                  stackSlug={stackSlug}
                  isPrivate={isPrivate}
                  isAdmin={isAdmin}
                />
              )}
            </Main>
            {hideDecisions ? (
              <SidebarToggle numDecisions={count} />
            ) : (
              <Sidebar>
                <SidebarContent innerRef={sidebarRef}>
                  {/* {!activeService && (!privateMode || planPermission) && (
                    <SidebarSection>
                      <Advert
                        placement="stack-profile"
                        requestPath={stack.path}
                        contentGroupPage="Stacks"
                        theme={SLIM}
                        maxWidth={SIDEBAR_WIDTH}
                        objectType="Stack"
                        objectId={stack.slug}
                        impressionType={AD_IMPRESSION}
                      />
                    </SidebarSection>
                  )} */}
                  {activeService && (
                    <ServiceDetailsCard
                      service={activeService}
                      canEdit={editPermission || isEditStagesAllowed}
                      stagePermission={isPrivate && stagePermission}
                    />
                  )}
                  {isOwner && (
                    <SidebarSection>
                      {!isUserImpersonated && (
                        <ComposerProvider privateMode={privateMode} debug>
                          <Composer
                            analyticsPayload={{stackIdentifier: stack.path}}
                            refetchQueries={refetchQueries}
                          />
                        </ComposerProvider>
                      )}
                    </SidebarSection>
                  )}
                  <div>
                    {!isOwner && !activeService && !privateMode && (
                      <SidebarSection innerRef={questionCtaRef}>
                        <AskQuestionCta
                          ownerName={ownerMeta.ownerName}
                          tool={activeService}
                          stack={stack}
                        />
                      </SidebarSection>
                    )}
                  </div>
                  <StackDecisions
                    activeService={activeService}
                    isOwner={isOwner}
                    ownerName={ownerMeta.ownerName}
                    stack={stack}
                    limitDecisions={limitDecisions}
                    sendAnalyticsEvent={sendAnalyticsEvent}
                    setCount={setCount}
                    activeServiceId={activeServiceId}
                  />
                </SidebarContent>
              </Sidebar>
            )}
          </Content>
          {signin && <SigninDesktopModal redirect={stack.path} />}
        </Page>
      )}
    </>
  );
};

StackProfile.propTypes = {
  loading: PropTypes.bool,
  stack: PropTypes.object,
  stackOwnerType: PropTypes.string,
  currentUser: PropTypes.object,
  activeServiceId: PropTypes.string,
  route: PropTypes.string,
  setActiveService: PropTypes.func,
  limitDecisions: PropTypes.bool,
  setLimitDecisions: PropTypes.func,
  sendAnalyticsEvent: PropTypes.func,
  storageProvider: PropTypes.object,
  toolItems: PropTypes.object
};

export default compose(withSendAnalyticsEvent)(StackProfile);
